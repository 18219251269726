import gql from 'graphql-tag';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Select, Skeleton, Space, Tag, Typography } from 'antd';
import { buttonBorder, floatingRight, spaceWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { NewClusterCreation } from 'components/Clusters/ClustersList';
import { DeploymentStep2 } from 'components/SharedComponents/CreateSteps/CreateSteps';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { layout, updateDeployment } from '../common';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

export const SelectDeploymentTargetPage = (props: { deployment: iDeployment }) => {
  const deployment: iDeployment = props.deployment;
  const history = useHistory();

  const { loading, error, data, refetch } = useAuthedQuery(
    gql`
      query RegionsController_regionsForProject($projectId: Int!) {
        RegionsController_regionsForProject(projectId: $projectId) {
          regions {
            id
            tenant
            name
            title
            kubernetesVersion
            cloudProvider
            isPublic
          }
          disabledRegions
        }
        ProjectController_getProject(projectId: $projectId) {
          id
          name
          title
          description
          gitInitialized
        }
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    { skip: !Number(deployment?.projectId), variables: { projectId: Number(deployment?.projectId) } },
  );

  if (error || loading) return <Skeleton active={true} loading={true} />;

  const tags = data?.TagsController_tagsList || [];

  const regions = data?.RegionsController_regionsForProject?.regions || [];
  const disabledRegions = data?.RegionsController_regionsForProject?.disabledRegions || [];

  const formData = () => {
    const deploymentName = () => (
      <Item
        name="name"
        label="Application name"
        initialValue={deployment.name}
        rules={[{ message: 'Please input your application name!', required: true }]}
      >
        <Input placeholder="Enter Application Name Here" />
      </Item>
    );

    const clusterSelection = () => (
      <Item name="region" label="Kubernetes cluster" initialValue={deployment.region}>
        <Select defaultValue={deployment.region} placeholder="Choose Cluster Here">
          <Option value={-1} key={0}>
            Connect cluster later
          </Option>
          {[...regions]
            .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : a.isPublic - b.isPublic))
            .map((v: iRegionModel) => (
              <Option value={v.id} key={v.id} disabled={disabledRegions.includes(v.id)}>
                <Tag color={v.isPublic ? 'grey' : 'green'} title="Kubernetes version">
                  {v.isPublic ? 'Shared' : 'Private'}
                </Tag>
                <Tag color={v.kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, marginTop: 5 }}>
                  Kubernetes {v.kubernetesVersion || 'N/A'}
                </Tag>
                {v.title} {!disabledRegions.includes(v.id) ? '' : '(not available, in this cluster already exists this application type)'}
              </Option>
            ))}
          <Option key={'New_Cluster'}>
            <TipLeft tip="Select this to create a new Cluster">
              <NewClusterCreation />
            </TipLeft>
          </Option>
        </Select>
      </Item>
    );

    const confirmButton = () => (
      <Item>
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save and continue
          </Button>
        </BottomButtons>
      </Item>
    );

    const deploymentForm = () => {
      const handleOnFinishForm = async values => {
        await updateDeployment(deployment.id, values);
        await refetch();
        history.push(`/app/${props.deployment.id}/setting/customise`);
      };
      return (
        <Form onFinish={handleOnFinishForm} {...layout}>
          {deploymentName()}

          {tags?.length && (
            <Item name="tags" label="Application tags" initialValue={deployment.tags}>
              <Select mode="multiple" defaultValue={deployment.tags} placeholder="Tags">
                {tags.map(elem => (
                  <Option value={elem.id} key={elem.id}>
                    <Tag color={elem.color}> {elem.name} </Tag>
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          {clusterSelection()}
          {confirmButton()}
        </Form>
      );
    };

    return deploymentForm();
  };

  const createDeploymentStep2 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <DeploymentStep2 />
      <Space>
        <Text />
      </Space>
      {formData()}
    </Space>
  );

  return createDeploymentStep2();
};

import moment from 'moment';
import { useApiQuery } from '../../../../utils/common';
import { versionsService } from '../../../../services/versions.service';
import { Button, Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons/lib';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';
import { iDeployment } from 'shared/deployment';

const { Text } = Typography;

export default function VersionHistory(props: {
  deployment: iDeployment;
  versionId: number;
  installUpdates: (deployment: iDeployment, version: any) => void;
}) {
  const [version] = useApiQuery(() => versionsService.getInfoUpdateDeployment(props.versionId), [props.versionId]);

  const handleClick = (elem: any) => {
    elem.name = elem.description;
    props.installUpdates(props.deployment, elem);
  };

  return version
    ? version.versions.map(elem => {
        const formattedDate = moment(elem.createdAt).format('DD:MM:YYYY ~ HH:mm:ss A');
        const tagTooltip = [
          { title: 'Element ID', content: `ID: ${elem.id}` },
          { title: 'Element name', content: elem.name },
          { title: 'Element channel', content: elem.channel },
          { title: 'Element created at', content: formattedDate },
        ];
        return (
          <Space direction="vertical" key={elem.id}>
            <Space direction="horizontal">
              <Button type="primary" onClick={() => handleClick(elem)}>
                Install
              </Button>
              &nbsp; <ArrowRightOutlined /> &nbsp;
              {tagTooltip.map(item => (
                <TipBottom tip={item.title} key={item.title}>
                  <Tag color="green"> {item.content} </Tag>
                </TipBottom>
              ))}
              {'\u00A0'.repeat(10)}
            </Space>
            <Text />
            <Text />
          </Space>
        );
      })
    : '';
}

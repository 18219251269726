import React from 'react';
import { Editor, EditorProps } from '@monaco-editor/react';
import { getThemeName, Theme } from 'utils/theme';

const getTheme = () => {
  const theme: Theme = getThemeName();
  return theme === 'dark' ? 'vs-dark' : 'vs';
};

const UnifieEditor: React.FC<Omit<EditorProps, 'theme'>> = props => {
  const theme = getTheme();

  return <Editor {...props} theme={theme} />;
};

export default UnifieEditor;

// @ts-ignore
const WebSocket = typeof window !== 'undefined' ? window.WebSocket : require('ws');

let wsApiSocket: any = null;
let wsApiSocket_connected = false;
let wsApiSocket_started = false;
let wsApiSocket_authStatus = null;
let wsApiSocket_errorCount = 0;
let wsApiSocket_authErrorCount = 0;

export interface WsApiAuthTokenData {
  type: string;
  id?: string | number;
  tenantId?: string | number;
  permissions?: string[];
}

type wsMessageType = 'connected' | 'auth' | 'event' | 'stats' | 'answer' | 'ping' | 'pong';
type wsMessagePayload = {
  status: 'success' | 'error';
  target?: any;
  type: wsMessageType;
  data: any;
  errorCode?: number;
  name?: string;
  queryId?: string;
  sender?: WsApiAuthTokenData;
};

export interface WsApiAuthData {
  token: string;
}

function getConnectionIdFromToken(tokenData: WsApiAuthTokenData): string {
  let id = `${tokenData.type}`;
  if (tokenData?.tenantId) {
    id += `-${tokenData?.tenantId}`;
  }
  if (tokenData?.id) {
    id += `-${tokenData?.id}`;
  }
  return id;
}

function wsSendMessage(payload: wsMessagePayload, reTry: number = 0): Promise<boolean | any> {
  if (wsApiSocket_connected) {
    return new Promise((resolve, reject) => {
      try {
        // console.debug(`wsSendMessage: `, payload);
        wsApiSocket.send(JSON.stringify(payload));
        resolve(true);
      } catch (e) {
        console.error(`WsApiClient: error(1) `, e);
        reject(e);
      }
    });
  }

  if (reTry > 20) {
    return Promise.reject(`Max retries reached (${reTry})`);
  }

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await wsSendMessage(payload, reTry + 1);
        resolve(true);
      } catch (e) {
        console.error(`WsApiClient: error(2)  `, e);
        reject(e);
      }
    }, (reTry + 1) * 500);
  });
}

const apiEventCallbacks: { [key: string]: { cb: (data: any, queryId: string) => Promise<any>; id: string }[] } = {};

const answerCallBacks = {};

export class WsApiClient {
  getWsApiTokenFn: (error?: string) => WsApiAuthData = null;

  lastAuthApiToken = null;
  pingTimerId = null;
  reconnectTimerId = null;

  addEventCallback(name: string, callback: (data: any, queryId: string) => Promise<any>): string {
    if (!apiEventCallbacks[name]) {
      apiEventCallbacks[name] = [];
    }

    const uid = `uid${uuid36()}`;
    apiEventCallbacks[name].push({
      cb: callback,
      id: uid,
    });

    return uid;
  }
  removeEventCallback(name: string, uid: string) {
    if (!apiEventCallbacks[name]) {
      return;
    }
    apiEventCallbacks[name] = apiEventCallbacks[name].filter(e => e.id !== uid);
  }

  async _callEventCallback(name: string, data: any, queryId: string, sender: WsApiAuthTokenData) {
    if (!apiEventCallbacks[name]) {
      return;
    }
    for (const e of apiEventCallbacks[name]) {
      try {
        const res = await e.cb(data, queryId);
        if (res !== undefined && res !== null) {
          await wsSendMessage({ status: `success`, type: 'answer', data: res, queryId: queryId, target: getConnectionIdFromToken(sender) });
        }
      } catch (e) {
        console.error(`WsApiClient:callEventCallback:error:`, e);
        await wsSendMessage({
          status: `error`,
          type: 'answer',
          data: { error: e?.message || e },
          queryId: queryId,
          target: getConnectionIdFromToken(sender),
        });
      }
    }
  }

  async onPong() {
    // console.log(`WsApiClient:pong`);
    if (this.reconnectTimerId) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = null;
    }

    if (this.pingTimerId) {
      clearTimeout(this.pingTimerId);
      this.pingTimerId = null;
    }

    this.pingTimerId = setTimeout(async () => {
      await this.ping();
    }, 1000 * 10);
  }

  async ping() {
    if (wsApiSocket_authStatus) {
      // console.log(`WsApiClient:ping`);
      try {
        if (this.reconnectTimerId) {
          clearTimeout(this.reconnectTimerId);
          this.reconnectTimerId = null;
        }

        this.reconnectTimerId = setTimeout(async () => {
          console.error(`WsApiClient:ping:error: reconnect`);
          wsApiSocket.close();
        }, 1000 * 60);

        this.pingTimerId = setTimeout(async () => {
          // Second ping request - if first not answered
          await wsSendMessage({ status: `success`, type: 'ping', data: {} });
        }, 1000 * 20);

        // first ping request
        await wsSendMessage({ status: `success`, type: 'ping', data: {} });
      } catch (e) {
        console.error(`WsApiClient:auth:error:`, e);
      }
    }
  }

  async auth(wsApiData: WsApiAuthData, force: boolean = false) {
    if (!wsApiSocket_authStatus || force) {
      try {
        return await wsSendMessage({ status: `success`, type: 'auth', data: { token: wsApiData.token } });
      } catch (e) {
        console.error(`WsApiClient:auth:error:`, e);
      }
    }
  }

  async stats() {
    try {
      return await wsSendMessage({ status: `success`, type: 'stats', data: {} });
    } catch (e) {
      console.error(`WsApiClient:auth:error:`, e);
    }
  }

  _onAnswer(queryId: string, data: any) {
    try {
      if (answerCallBacks[queryId]) {
        answerCallBacks[queryId](data);
        delete answerCallBacks[queryId];
      }
    } catch (e) {
      console.error(`WsApiClient:onAnswer:error:`, e);
    }
  }

  start(host: string, useWss: boolean, getWsApiToken: () => WsApiAuthData) {
    if (getWsApiToken) {
      this.getWsApiTokenFn = getWsApiToken;
    }

    if (wsApiSocket_started) {
      return;
    }
    wsApiSocket_started = true;

    const connectionURL = `${useWss ? 'wss' : 'ws'}://${host}/ws-api`;
    console.log(`WsApiClient: start ws-api connection to ${connectionURL}`);
    wsApiSocket = new WebSocket(connectionURL);
    wsApiSocket.onopen = async e => {
      console.log('[ws-api] Connected');

      // socket.send('Hi');
      wsApiSocket_connected = true;
      wsApiSocket_authStatus = null;

      // Auth again
      await this.auth(this.getWsApiTokenFn(), true);
    };

    wsApiSocket.onmessage = async event => {
      const strData = event.data;
      try {
        const data: wsMessagePayload = JSON.parse(strData);
        const strDataShort = JSON.stringify(data.data).slice(0, 100);

        if (data.status !== 'success') {
          console.log(`[ws-api::${data.status}] ${data.type} ${strDataShort}`);
        }
        if (data.errorCode === 403) {
          console.error(`[ws-api::income-message]: auth error`, strDataShort);
          setTimeout(async () => {
            const token = this.getWsApiTokenFn();
            console.error(`[ws-api::income-message]: retry auth`, token);
            await this.auth(token, true);
          }, 1000);
        }
        wsApiSocket_errorCount = 0;
        if (data.type === 'pong') {
          await this.onPong();
        } else if (data.type === 'event') {
          await this._callEventCallback(data.name, data.data, data?.queryId, data?.sender);
        } else if (data.type === 'auth') {
          console.log(`[ws-api::income-message]: auth `, strDataShort);
          if (data?.data?.auth === 'accepted') {
            wsApiSocket_authStatus = true;
            wsApiSocket_authErrorCount = 0;
            await this.ping();
          } else {
            wsApiSocket_authStatus = false;
            wsApiSocket_authErrorCount += 1;
            console.error(`[ws-api::income-message]: auth error`, strDataShort);
            if (data?.data?.errorData?.name === 'TokenExpiredError') {
              //Note: We have a chance to get new token with error and send auth messages in loop

              const token = this.getWsApiTokenFn('TokenExpiredError');
              if (token) {
                if (token.token !== this.lastAuthApiToken?.token) {
                  this.lastAuthApiToken = token;
                  console.error(`[ws-api::income-message]: retry auth error`, token);
                  await this.auth(token, true);
                } else {
                  console.error(`[ws-api::income-message]: retry auth error: same token`, token);
                }
              } else {
                console.error(`[ws-api::income-message]: retry auth error: no token`, token);
              }
            } else if (data?.data?.errorData?.name === 'JsonWebTokenError') {
              if (data?.data?.errorData?.message === 'invalid signature') {
                console.error(`[ws-api::income-message]: Fatal error JsonWebTokenError=invalid signature`);
                // throw new Error(`ws-api::JsonWebTokenError: ${data?.data.errorData.message}`);
              } else {
                console.error(`[ws-api::income-message]: JsonWebTokenError`, data?.data.errorData.message);
              }
            } else {
              console.error(`[ws-api::income-message]: retry auth error: unknown`, data?.data?.errorData);
            }
          }
        } else if (data.type === 'answer') {
          // console.log(`[ws-api::income-answer]:`, strDataShort);
          await this._onAnswer(data.queryId, data.data);
        } else if (data.type === 'connected') {
          console.log(`[ws-api::income-message]: connected build `, data?.data?.build);
        } else {
          console.log(`[ws-api::income-message]: unknown message type`, strDataShort);
        }
      } catch (e) {
        console.error(`[ws-api::income-message]: error`, e, strData);
      }
    };

    wsApiSocket.onclose = event => {
      wsApiSocket_connected = false;
      wsApiSocket_authStatus = null;
      const reconnectLogMessage = `Reconnect in ${
        wsApiSocket_errorCount + wsApiSocket_authErrorCount
      }sec (${wsApiSocket_errorCount}/${wsApiSocket_authErrorCount})`;
      if (event.wasClean) {
        console.log(
          `[ws-api::close] code=${event.code} reason=${event.reason} errors=${wsApiSocket_errorCount + wsApiSocket_authErrorCount}`,
          reconnectLogMessage,
        );
      } else {
        console.warn(
          `[ws-api::close] with error code=${event.code} errors=${wsApiSocket_errorCount + wsApiSocket_authErrorCount}`,
          reconnectLogMessage,
        );
      }
      setTimeout(() => {
        wsApiSocket_started = false;
        wsApiSocket_authStatus = null;
        this.start(host, useWss, this.getWsApiTokenFn);
      }, wsApiSocket_errorCount * 1000 + wsApiSocket_authErrorCount * 1000);
    };

    wsApiSocket.onerror = error => {
      console.log(`[ws-api::error]`, error?.message || error);
      wsApiSocket_errorCount += 1;
    };
  }

  /**
   * Send event to server
   * @param eventName
   * @param payload
   */
  async sendEvent(target: string, eventName: string, payload: any, answerCallBack?: (data: any) => void) {
    let qId = null;

    if (answerCallBack) {
      qId = `qId${uuid36()}`;
      answerCallBacks[qId] = answerCallBack;
    }

    await wsSendMessage({ status: `success`, target: target, type: 'event', data: payload, name: eventName, queryId: qId });
  }

  /**
   * Send event to server
   * @param eventName
   * @param payload
   *
   * @example:
   *  const serverWs = new WsServerApi();
   *  serverWs.serverConnect(`backend`);
   *  const answer = await serverWs.client().remoteApiCall(`backend`, 'Test_status', { me: '??' });
   */
  remoteApiCall(target: string, eventName: string, payload: any, timeout: number = 50000): Promise<any> {
    let qId = `qId${uuid36()}`;
    return new Promise(async (resolve, reject) => {
      await this.sendEvent(target, eventName, payload, resolve);
      if (timeout > 0) {
        setTimeout(() => {
          delete answerCallBacks[qId];
          reject(`remoteApiCall::Timeout reached (${timeout}) target=${target} eventName=${eventName}`);
        }, timeout);
      }
    });
  }
}

function uuid36(len = 7) {
  return String(Math.random().toString(36) + Date.now().toString(36)).substring(2, 2 + len);
}

export interface iWSremoteApiCallAnswer {
  _api_id?: string;
  _api_uid?: string;
  error?: string;
  res?: any;
  target?: string;
}

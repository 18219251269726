import { RollbackOutlined, ReloadOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { Skeleton, Col, Select, Button, Table, Space, Row, Form, Tag, Alert, Typography } from 'antd';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { EventsActionDrawer } from './EventRow';
import { v4 as uuidv4 } from 'uuid';
import { spaceWidth } from 'utils/styles';

interface iEventsTableProps {
  eventsList: any[];
  regionId: number;
  refetch: () => void;
  loading: boolean;
  error: any;
  showCluster: boolean;
  showNamespace: boolean;
}

interface TableFilters {
  podName: string[];
  namespace?: string[];
  status: string[];
  reason: string[];
  cluster?: string[];
}

const { Text } = Typography;

export function EventsTable(props: iEventsTableProps) {
  const [hovered, setHovered] = useState(false);
  const defaultFilters = { podName: [], namespace: [], status: [], reason: [], cluster: [] };
  const [filters, setFilters] = useState<TableFilters>(defaultFilters);
  const [tempFilters, setTempFilters] = useState<TableFilters>(defaultFilters);

  if (props.loading) return <Skeleton active />;
  if (props.error) return <>{props.error}</>;

  const ClusterEventsList = props.eventsList || [];
  const clusterNames = props.showCluster ? Array.from(new Set(ClusterEventsList.map(item => item.region?.name))) : [];

  const podNames = Array.from(new Set(ClusterEventsList.map(item => item.involvedObject.name)));
  const namespaces = Array.from(new Set(ClusterEventsList.map(item => item.metadata.namespace)));
  const statuses = Array.from(new Set(ClusterEventsList.map(item => item.type)));
  const reasons = Array.from(new Set(ClusterEventsList.map(item => item.reason)));

  const filteredEvents = ClusterEventsList.filter(event => {
    return (
      (filters.podName.length === 0 || filters.podName.includes(event.involvedObject.name)) &&
      (filters.namespace.length === 0 || filters.namespace.includes(event.metadata.namespace)) &&
      (filters.status.length === 0 || filters.status.includes(event.type)) &&
      (filters.reason.length === 0 || filters.reason.includes(event.reason)) &&
      (props.showCluster ? filters.cluster.length === 0 || filters.cluster?.includes(event.region?.name) : true)
    );
  });

  const handleTempFilterChange = (key: string, values: string[]) => {
    setTempFilters(prev => ({ ...prev, [key]: values }));
  };

  const applyFilters = () => {
    setFilters(tempFilters);
  };

  const handleReset = () => {
    setTempFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  const columns = [
    props.showCluster && {
      title: 'Cluster',
      dataIndex: ['region'],
      key: 'cluster',
      render: (region: { id: number; name: string }) => <Link to={`/clusters/${region.id}/overview`}>{region.name}</Link>,
    },
    { title: 'Pod name', dataIndex: ['metadata', 'name'], key: 'name' },
    props.showNamespace && {
      title: 'Namespace',
      dataIndex: ['metadata', 'namespace'],
      key: 'namespace',
    },
    {
      title: 'Status',
      dataIndex: 'type',
      key: 'type',
      render: (value: string) => {
        const color = value === 'Warning' ? 'orange' : value === 'Error' ? 'red' : value === 'Normal' ? 'green' : 'black';
        return <Tag color={color}>{value}</Tag>;
      },
    },
    { title: 'Reason', dataIndex: 'reason', key: 'reason' },
    {
      title: 'Event message',
      dataIndex: 'message',
      key: 'message',
      render: (value: string) => {
        return (
          <Typography.Text>
            {`${value}`.slice(0, 500)}
            {value.length > 490 ? '...' : ''}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      sorter: {
        compare: (a: { count: number }, b: { count: number }) => a.count - b.count,
        multiple: 3,
      },
    },
    {
      title: 'Time',
      dataIndex: 'lastTimestamp',
      key: 'lastTimestamp',
      sorter: (a: { lastTimestamp: string }, b: { lastTimestamp: string }) => moment(a.lastTimestamp).valueOf() - moment(b.lastTimestamp).valueOf(),
      render: (time: string) => moment(time).format('DD-MM-YYYY ~ hh:mm:ss A'),
    },
    {
      title: 'Action',
      dataIndex: 'type',
      key: 'type',
      render: (value: any, record: any, index: number) => (
        <EventsActionDrawer
          record={record}
          regionId={value?.region?.id || props.regionId}
          eventsList={props.eventsList}
          index={index}
          showCluster={props.showCluster}
          showNamespace={props.showNamespace}
        />
      ),
    },
  ].filter(Boolean);

  const getFilterOptions = () => {
    const options: { key: string; label: string; options: string[] }[] = [];

    if (props.showCluster) {
      options.push({ key: 'cluster', label: 'Filter by Cluster', options: clusterNames });
    }

    options.push({ key: 'podName', label: 'Filter by Pod Name', options: podNames });

    if (props.showNamespace) {
      options.push({ key: 'namespace', label: 'Filter by Namespace', options: namespaces });
    }

    options.push({ key: 'status', label: 'Filter by Status', options: statuses }, { key: 'reason', label: 'Filter by Reason', options: reasons });

    return options;
  };

  const filterOptions = getFilterOptions();

  const FilterSection = () => {
    const FilterSelect = ({
      label,
      value,
      onChange,
      options,
    }: {
      label: string;
      value: string[];
      onChange: (values: string[]) => void;
      options: string[];
    }) => (
      <Form.Item label={<Text strong>{label}</Text>}>
        <Select mode="multiple" placeholder={`Select ${label}`} allowClear value={value} onChange={onChange}>
          {options.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );

    const renderQueryButtons = () => (
      <Form.Item>
        <Space>
          <Button type="default" icon={<RollbackOutlined />} onClick={handleReset}>
            Reset
          </Button>
          <Button type="primary" onClick={applyFilters}>
            Query
          </Button>
        </Space>
      </Form.Item>
    );

    const FilterSectionContent = (
      <div>
        <Form layout="vertical">
          <Row gutter={16} align="bottom">
            {filterOptions.map(({ key, label, options }) => (
              <Col span={8} key={key}>
                <FilterSelect label={label} value={tempFilters[key]} onChange={values => handleTempFilterChange(key, values)} options={options} />
              </Col>
            ))}

            {filterOptions.length > 3 ? (
              <Col span={8}>{renderQueryButtons()}</Col>
            ) : (
              <Row justify="end" style={spaceWidth}>
                <Col>{renderQueryButtons()}</Col>
              </Row>
            )}
          </Row>
        </Form>
      </div>
    );

    return (
      <div style={{ marginBottom: '16px' }}>
        <Alert message={null} description={FilterSectionContent} action={null} className="alert-block" />
      </div>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Skeleton active loading={props.loading && !props.error && !ClusterEventsList}>
        {FilterSection()}
        <Row justify="end">
          <Col>
            <TipLeft tip="Click here to refresh the events">
              <Button
                type="dashed"
                icon={<ReloadOutlined spin={hovered} />}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={async () => await props.refetch()}
              >
                Refresh
              </Button>
            </TipLeft>
          </Col>
        </Row>

        <Table columns={columns} dataSource={filteredEvents.map(event => ({ ...event, uniqueId: uuidv4() }))} rowKey={record => record.uniqueId} />
      </Skeleton>
    </Space>
  );
}

import gql from 'graphql-tag';
import { iDeployment } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { Tag } from 'antd';

interface iTagModel {
  id: number;
  tenant: number;
  name: string;
  color: string;
  type: string;
  isPublic: boolean;
}

interface iDeploymentTagProps {
  deployment: iDeployment;
  tags?: iTagModel[];
}

/**
 * Render deployment tags and project tags for the deployment
 * @param props
 * @returns JSX.Element
 */
export const DeploymentTag = (props: iDeploymentTagProps) => {
  const shouldFetchTags = !props.tags;

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
    { skip: !shouldFetchTags },
  );

  const tags = props.tags || data?.TagsController_tagsList || [];

  return (
    <>
      {(props?.deployment?.tags || []).map((tag: number) => {
        const tagData = tags.find(t => t.id === tag);
        return tagData ? (
          <Tag key={tagData.id} color={tagData.color}>
            {tagData.name}
          </Tag>
        ) : null;
      })}
      {(props?.deployment?.ProjectModel?.tags || []).map((tag: string, i: number) => (
        <Tag key={i}>{tag}</Tag>
      ))}
    </>
  );
};

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton, Row, Result } from 'antd';
import { ClusterConfigPage } from './settings/ClusterConfigPage';
import { RegionMetacontrollerLogsLogs } from './monitoring/RegionMetacontrollerLogs';
import { ApiAgentStatus } from './monitoring/ApiAgentStatus';
import { ClusterNodesMonitoring } from './monitoring/ClusterNodesMonitoring';
import { useUser } from 'utils/common';
import { iMyUserData } from 'shared/user';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { ClusterPvcList } from './monitoring/ClusterPvcList';
import { TitleUIRow } from 'layout/TitleUI';
import { ClusterDangerZone } from './ClusterDangerZone';
import { ClusterMonitoring } from './monitoring/ClusterMonitoring';

import ClusterAuditLogs from './ClusterAuditLogs';
import ClusterOverView from './OverView/ClusterOverView';
import OnePluginPage from './Integrations/OnePluginPage';
import { ClusterJobs } from './ClusterJobs';
import { ClusterEvents } from './monitoring/ClusterEvents';
import ClusterIntegrations from './Integrations/ClusterIntegrations';
import ClusterSecurityReport from './Security/ClusterSecurityReport';

export interface iClusterOverViewProps {}
export const ClusterMainPage = (props: iClusterOverViewProps) => {
  const params: any = useParams();
  const history = useHistory();
  const [current, setCurrent] = useState(params.tabName || `overview`);
  const { loading, region, refetch } = useRegionsController_getRegionById(params.regionId);
  const user: iMyUserData = useUser();

  useEffect(() => {
    setCurrent(params?.tabName || `overview`);
  }, [params?.tabName]);

  if (loading || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  if (!region) {
    return <Result status="404" title="404" subTitle="Sorry, the cluster you are looking for, is not found..." />;
  }

  if (region?.deletedAt) {
    return <Result status="404" title="404" subTitle="Sorry, the cluster you are looking for, was removed..." />;
  }

  if (region?.configureStatus === 'new') {
    debugger;
    history.push(`/cluster/install/${region.id}/agent`);
    return <Skeleton active={true} loading={true} />;
  }

  const components = {
    overview: () => <ClusterOverView region={region} />,
    settings: () => <ClusterConfigPage region={region} />,
    nodes: () => <ClusterNodesMonitoring region={region} />,
    pvc: () => <ClusterPvcList region={region} />,
    monitoring: () => <ClusterMonitoring region={region} />,
    integrations: () => {
      if (params.tabName === 'integrations' && params.subTabName) return <OnePluginPage region={region} refetchRegionData={refetch} />;
      return <ClusterIntegrations region={region} />;
    },
    'api-agent-status': () => <ApiAgentStatus region={region} />,
    'sync-logs': () => <RegionMetacontrollerLogsLogs region={region} />,
    'audit-logs': () => <ClusterAuditLogs region={region} />,
    events: () => <ClusterEvents region={region} />,
    jobs: () => <ClusterJobs region={region} />,
    'danger-zone': () => <ClusterDangerZone region={region} />,
    security: () => <ClusterSecurityReport region={region} />,
  };

  if (!components[current]) {
    return <Result status="404" title="404" subTitle="The content you are looking for, does not exists... " />;
  }

  return (
    <>
      <TitleUIRow title={`Cluster ${region?.name || ''} - ${params?.tabName || `overview`}`} />
      <Row> {components[current]()} </Row>
    </>
  );
};

import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { GeneralSettings } from './GeneralSettings';
import { KubeCost } from './KubeCost';
import { AwsPrice } from './AwsPrice';
import { Skeleton, Space, Tabs, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';
import { LoadBalancer } from './LoadBalancer';
import { TerraformJobsConfig } from './TerraformJobsConfig';

export interface iClusterConfigPageProps {
  region: iRegionModel;
}

const { Text } = Typography;
const { TabPane } = Tabs;

export const ClusterConfigPage = (props: iClusterConfigPageProps) => {
  const history = useHistory();
  const params: any = useParams();
  const [tabName, setCurrent] = useState(params.subTabName || 'general');

  useEffect(() => {
    setCurrent(params?.subTabName || 'general');
  }, [params?.subTabName]);

  const { loading, error, region } = useRegionsController_getRegionById(props.region.id);

  if (loading || error) return <Skeleton active loading />;

  const handleTabChange = (key: string) => {
    setCurrent(key);
    history.push(`/clusters/${props.region.id}/settings/${key}`);
  };

  const tabItems = [
    { title: 'General', key: 'general' },
    { title: 'Kube cost', key: 'kube-cost' },
    region.cloudProvider === 'aws' && { title: 'AWS Price', key: 'aws-price' },
    { title: 'Load balancer', key: 'load-balancer' },
    region.cloudProvider === 'aws' && { title: 'Terraform configuration', key: 'terraform-config' },
  ].filter(Boolean);

  const tabComponents: { [key: string]: JSX.Element } = {
    general: <GeneralSettings region={region} />,
    'kube-cost': <KubeCost region={region} />,
    'aws-price': <AwsPrice region={region} />,
    'load-balancer': <LoadBalancer region={region} />,
    'terraform-config': <TerraformJobsConfig region={region} />,
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Tabs activeKey={tabName} onChange={handleTabChange}>
        {tabItems.map(({ title, key }) => (
          <TabPane
            key={key}
            tab={
              <Link to={`/clusters/${props.region.id}/settings/${key}`}>
                <Text> {title} </Text>
              </Link>
            }
          />
        ))}
      </Tabs>
      {tabComponents[tabName]}
    </Space>
  );
};

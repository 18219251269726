import { ReactElement } from 'react';
import { Button, Form, notification, Typography } from 'antd';
import { projectService } from 'services/project.service';
import { useHistory } from 'react-router-dom';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { iDeployment } from 'shared/deployment';
import { buttonBorder } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { DockerWizard, DockerWizardUI } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { PROJECT_CODE_NAME } from 'interface/common';

const { Text, Title } = Typography;
const { Item } = Form;

export const NewServicePreFill_docker = (props: { serviceName: string; deployment: iDeployment; disabled: boolean }): ReactElement => {
  const history = useHistory();

  const projService: iCloudProjectServiceYamlSpecs = {
    name: props.serviceName,
    uiType: 'docker',
  };

  const handleCreateService = async () => {
    if (props.disabled) {
      return false;
    }

    const serviceType = 'docker';
    const projectId = props.deployment?.projectId;
    const newService: iCloudProjectServiceYamlSpecs = { name: props.serviceName, uiType: serviceType };
    const { error } = await projectService.setNewService(projectId, newService, props.deployment.id);
    if (error) {
      notification.error({ message: `Error: ${error}` });
      return false;
    }

    notification.success({ message: 'Ready' });
    return true;
  };
  return (
    <DockerWizardUI
      key={props.serviceName}
      fileName={`${PROJECT_CODE_NAME}/services/${props.serviceName}/template.yaml`}
      serviceName={props.serviceName}
      project={props.deployment.ProjectModel}
      deployment={props.deployment}
      onSubmit={async () => {
        debugger;
        if (!(await handleCreateService())) {
          throw new Error('Error creating service');
        }
      }}
      onAfterSubmit={async () => {
        history.push(`/app/${props.deployment.id}/configuration/services/${props.serviceName}/main`);
      }}
      content={''}
      projService={projService}
    />
  );
};

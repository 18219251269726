import { Typography, Space, Button, Alert, Modal } from 'antd';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { buttonSize } from 'utils/styles';
import { updateDeployment } from '../../common';

const { Text } = Typography;

export const PauseApplicationBtn = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;
  const [isEnabled, setIsEnabled] = useState<boolean>(deployment?.isEnabled);

  useEffect(() => {
    setIsEnabled(props?.deployment?.isEnabled);
  }, [props?.deployment?.isEnabled]);

  const deploymentPause = async () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: isEnabled ? 'Do you want to pause this Application?' : 'Do you want to resume this Application?',
      onOk: async () => {
        const setVal = !isEnabled;
        setIsEnabled(setVal);
        await updateDeployment(deployment.id, { isEnabled: setVal });
      },
    });
  };
  const alertDescription = (
    <Space direction="vertical">
      <Text> Pausing your service will temporarily suspend it and stop all running instances. </Text>
      <Text> If Application is paused, You can reactivate it at any time by clicking "Resume." </Text>
    </Space>
  );

  const alertAction = (
    <TipLeft tip={isEnabled ? 'It will remove Application from Cluster. This action can loss Application data' : 'Run application again'}>
      <Button loading={!deployment} onClick={deploymentPause} style={buttonSize} danger={isEnabled} type="dashed">
        {isEnabled ? 'Pause application' : 'Resume application'}
      </Button>
    </TipLeft>
  );

  return <Alert message={<Text strong> Pause Application </Text>} description={alertDescription} action={alertAction} className="alert-block" />;
};

import gql from 'graphql-tag';
import { useState } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { EventsTable } from 'components/monitoring/EventRenders/EventsTable';

interface iClusterEventsProps {
  region: iRegionModel;
}

export function ClusterEvents(props: iClusterEventsProps) {
  const [hovered, setHovered] = useState(false);

  const { loading, error, data, refetch } = useAuthedQuery(
    gql`
      query ApiAgentController_listEventForAllNamespaces($regionId: Int!) {
        ApiAgentController_listEventForAllNamespaces(regionId: $regionId) {
          status
          message
          events
        }
      }
    `,
    { variables: { regionId: props.region.id } },
  );

  return (
    <EventsTable
      regionId={props.region?.id}
      showCluster={false}
      showNamespace={true}
      eventsList={data?.ApiAgentController_listEventForAllNamespaces?.events || []}
      refetch={refetch}
      loading={loading}
      error={error}
    />
  );
}

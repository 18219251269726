import { TipTopLeft } from '../../SharedComponents/Tooltip/Tooltip';
import { Button, Modal } from 'antd';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { iRegionModel } from '../../../shared/deployment';
import { Tf_AwsEks_JobEditForm } from '../new-cluster-v2/Tf_AwsEks_JobEditForm';

export const ClusterPlanBytton = (props: { planIsDone: number; clusterAreReady: boolean; region: iRegionModel; refetchJobList: () => void }) => {
  const [newJobFunction, newJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newJob($regionId: Int!) {
      JobsController_newJob(regionId: $regionId) {
        id
        status
        region
        createdAt
        updatedAt
        variables
      }
    }
  `);

  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => setVisible(true);
  const handleCloseModal = () => setVisible(false);

  useEffect(() => {
    props.refetchJobList();
    if (newJobRes.data && !visible) {
      setEditData(newJobRes?.data?.JobsController_newJob);
      handleOpenModal();
    }
  }, [newJobRes.data]);

  return (
    <>
      <Modal open={visible} title={`Cluster configurations`} width={800} onCancel={handleCloseModal} footer={null} destroyOnClose>
        {editData && <Tf_AwsEks_JobEditForm regionId={props.region.id} editJob={editData} onFinish={handleCloseModal} onCancel={handleCloseModal} />}
      </Modal>

      <TipTopLeft
        tip={
          'This will create a new plan job. It will create a new plan job and run the terraform plan command. It will not apply any changes to your infrastructure.'
        }
      >
        <Button
          onClick={async () => {
            await newJobFunction({ variables: { regionId: Number(props.region.id) } });
          }}
          type={!props.planIsDone ? 'primary' : 'default'}
        >
          {props.clusterAreReady ? `Cluster modification plan` : `Cluster creation plan`}
        </Button>
      </TipTopLeft>
    </>
  );
};

import { Typography, Space, Switch, Button, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { buttonSize } from 'utils/styles';
import { updateDeployment } from '../../common';

const { Text } = Typography;

export const AutoDeploySettings = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;
  const [useManualApply, setuseManualApply] = useState<boolean>(deployment?.useManualApply);

  useEffect(() => {
    setuseManualApply(deployment?.useManualApply);
  }, [deployment?.useManualApply]);

  const useManualApplyFunctions = async checked => {
    setuseManualApply(checked);
    await updateDeployment(deployment.id, { useManualApply: checked });
  };

  const alertDescription = (
    <Space direction="vertical">
      <Text>For critical services we recommend to turn off auto deploy option</Text>
      <Space direction="horizontal">
        <Switch disabled={!deployment} checked={!useManualApply} onChange={v => useManualApplyFunctions(!v)} />
        <Text> Apply changes immediately </Text>
      </Space>
    </Space>
  );

  const alertAction = (
    <Link to={`/app/${deployment.id}/status/deploy`}>
      <Button style={buttonSize}>Check pending changes</Button>
    </Link>
  );

  return (
    <Alert
      message={<Text strong> Application deploy settings </Text>}
      description={alertDescription}
      action={useManualApply ? alertAction : null}
      className="alert-block"
    />
  );
};

import { notification } from 'antd';
import gql from 'graphql-tag';
import { authService } from 'services/auth.service';

const UPDATE_DEPLOYMENT = gql`
  mutation UpdateDeployment($deploymentId: Int!, $newValues: JSON!) {
    DeploymentsController_updateDeployment(deploymentId: $deploymentId, newValues: $newValues) {
      application {
        id
        name
        env
      }
      error
    }
  }
`;

export const updateDeployment = async (id: number, values: Record<string, any>) => {
  try {
    const res = await authService.apolloQuery({
      query: UPDATE_DEPLOYMENT,
      variables: {
        deploymentId: id,
        newValues: values,
      },
    });
    // const res = await updateDeploymentMutation({
    //   variables: {
    //     deploymentId: id,
    //     newValues: values,
    //   },
    // });
    debugger;
    const result = res.data?.DeploymentsController_updateDeployment;

    if (result?.error) {
      notification.error({ description: result.error, message: 'Update failed' });
      return null;
    }

    notification.success({ description: 'Saved', message: '' });
    return result.application;
  } catch (error) {
    console.error('Mutation Error:', error);
    notification.error({ description: 'Something went wrong!', message: '' });

    return null;
  }
};

export const layout = { labelCol: { span: 6 }, wrapperCol: { span: 20 } };

export const tailLayout = { wrapperCol: { offset: 4, span: 20 }, style: { textAlign: 'right' } };

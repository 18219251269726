import gql from 'graphql-tag';
import moment from 'moment';
import { DiffEditor } from '@monaco-editor/react';
import { SetStateAction, useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { iSpecsRevision } from 'shared/SpecsRevisions';
import { arrToYamlString } from 'shared/yaml';
import { buttonBorder, contentJustified, deploymentCardHeight, noWrap, revisionWidth, spaceWidth, topsMargin } from 'utils/styles';
import { useAuthedMutation, useAuthedQuery } from 'utils/qlAuth';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { Alert, Button, Card, Col, Flex, Input, Modal, Popconfirm, Row, Select, Skeleton, Space, Timeline, Typography } from 'antd';
import { authService } from 'services/auth.service';
import { CopyTwoTone, DownOutlined } from '@ant-design/icons';
import { TipLeft, TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeploymentTag } from '../DeploymentTag';
import { Link } from 'react-router-dom';

interface iNewRevisionForm {
  deployment: iDeployment;
  onNewRevision: () => void;
  beforeDeploy?: (iDeployButton: any) => boolean | Promise<boolean>;
  btnText?: string;
  applyLater?: boolean;
  disabled?: boolean;
}

interface iNewRevision {
  target: { value: SetStateAction<string> };
}

interface iSpecsRevisions {
  deployment: iDeployment;
}

interface isetRevisionId {
  (value: SetStateAction<number>): void;
  (value: SetStateAction<number>): void;
  (arg0: number): void;
}

const { Text } = Typography;
const { TextArea } = Input;

export const NewRevisionForm = (props: iNewRevisionForm) => {
  const { deployment, beforeDeploy, applyLater, disabled, btnText } = props;
  const [revisionName, setRevisionName] = useState('');
  const [revisionDescription, setRevisionDescription] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const hasChangesForApply = deployment?.hasChangesForApply;

  const { data: affectedDeployments } = useAuthedQuery(
    gql`
      query DeploymentsController_getAffectedDeployments($deploymentId: Int!) {
        DeploymentsController_getAffectedDeployments(deploymentId: $deploymentId) {
          id
          name
        }
      }
    `,
    { skip: !deployment.id, variables: { deploymentId: deployment.id } },
  );

  const createNewRevision = async () => {
    if (beforeDeploy) {
      const res = await beforeDeploy(deployment);
      props.onNewRevision();
      if (!res) return;
    }

    await authService.getApolloClient().query({
      query: gql`
        mutation SpecsRevisionController_createRevision($applicationId: Int!, $name: String, $description: String, $applyNow: Boolean) {
          SpecsRevisionController_createRevision(applicationId: $applicationId, name: $name, description: $description, applyNow: $applyNow) {
            id
          }
        }
      `,
      variables: { applicationId: deployment.id, name: revisionName, description: revisionDescription, applyNow: !applyLater },
    });

    props.onNewRevision();
    setShowRevisionModal(false);
  };

  return (
    <>
      <Button
        disabled={disabled || false}
        type={hasChangesForApply ? 'primary' : 'default'}
        onClick={() => {
          if (affectedDeployments?.DeploymentsController_getAffectedDeployments?.length > 0) {
            setShowConfirmModal(true);
          } else {
            setShowRevisionModal(true);
          }
        }}
      >
        {btnText || `Create new revision`}
      </Button>

      {/* Confirmation Modal (Step 1) */}
      <Modal
        title="Affected Deployments"
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onOk={() => {
          setShowConfirmModal(false);
          setShowRevisionModal(true);
        }}
        width={600}
        okText="Proceed"
        cancelText="Cancel"
      >
        <Alert message="Creating a revision will affect these deployments. Kindly Re-deploy them Manually" type="warning" />

        <Space direction="vertical" style={{ ...topsMargin, ...spaceWidth }}>
          {affectedDeployments?.DeploymentsController_getAffectedDeployments?.map(deployment => (
            <>
              <Link to={!deployment.isReady ? `/app/${deployment.id}/configuration/settings/general` : `/app/${deployment.id}/status/overview`}>
                <Card size="small" type="inner" title={deployment.name} data-cy={deployment.id} bordered={false} styles={{ body: { padding: 0 } }} />
              </Link>
            </>
          ))}
        </Space>
      </Modal>

      {/* Revision Form Modal (Step 2) */}
      <Modal title="Create New Revision" open={showRevisionModal} onCancel={() => setShowRevisionModal(false)} onOk={createNewRevision}>
        <Space direction="vertical" style={spaceWidth}>
          <Text>Provide a revision name and description:</Text>
          <Input placeholder="Enter Revision name" value={revisionName} onChange={e => setRevisionName(e.target.value)} />
          <TextArea
            placeholder="Enter Revision description"
            value={revisionDescription}
            onChange={e => setRevisionDescription(e.target.value)}
            rows={5}
          />
        </Space>
      </Modal>
    </>
  );
};

export const SpecsRevisions = ({ deployment }: iSpecsRevisions) => {
  const { lastRevisionId, id: deploymentId } = deployment;
  const [leftRevisionId, setLeftRevisionId] = useState<number | null>(lastRevisionId || null);
  const [rightRevisionId, setRightRevisionId] = useState<number | null>(lastRevisionId || null);
  const [leftRevisionData, setLeftRevisionData] = useState<Array<{ specs: any; vars: any; services: any }>>([]);
  const [rightRevisionData, setRightRevisionData] = useState<Array<{ specs: any; vars: any; services: any }>>([]);
  const [showLastApplied, setShowLastApplied] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isEditorLoading, setIsEditorLoading] = useState(false);
  const deployID = Number(deployment?.id);
  const leftVer = Number(leftRevisionId);
  const rightVer = Number(rightRevisionId);

  useEffect(() => {
    setLeftRevisionId(lastRevisionId);
  }, [lastRevisionId]);

  const specsRevisions = useAuthedQuery(
    gql`
      query SpecsRevisionController_getList($applicationId: Int!) {
        SpecsRevisionController_getList(applicationId: $applicationId) {
          id
          createdAt
          name
          userId
          description
        }
      }
    `,
    { skip: !deployID, variables: { applicationId: deployID } },
  );

  const { data: leftSpecsRevisionData } = useAuthedQuery(
    gql`
      query SpecsRevisionController_getOne($applicationId: Int!, $leftRevisionId: Int!) {
        SpecsRevisionController_getOne(applicationId: $applicationId, revisionId: $leftRevisionId) {
          id
          createdAt
          name
          userId
          description
          specs
        }
      }
    `,
    { skip: !leftVer, variables: { leftRevisionId: leftVer, applicationId: deployID } },
  );

  const { data: rightSpecsRevisionData } = useAuthedQuery(
    gql`
      query SpecsRevisionController_getOne($applicationId: Int!, $rightRevisionId: Int!) {
        SpecsRevisionController_getOne(applicationId: $applicationId, revisionId: $rightRevisionId) {
          id
          createdAt
          name
          userId
          description
          specs
        }
      }
    `,
    { skip: !rightVer, variables: { rightRevisionId: rightVer, applicationId: deployID } },
  );

  const [SpecsRevisionController_applyRevision] = useAuthedMutation(gql`
    mutation SpecsRevisionController_applyRevision($applicationId: Int!, $revisionId: Int!) {
      SpecsRevisionController_applyRevision(applicationId: $applicationId, revisionId: $revisionId)
    }
  `);

  const { data: revSpec } = specsRevisions;
  const revisions: iSpecsRevision[] = revSpec?.SpecsRevisionController_getList;

  useEffect(() => {
    const updateData = (data: { SpecsRevisionController_getOne: { specs: any } }, prev: { specs: any; vars: any; services: any }[]) =>
      data?.SpecsRevisionController_getOne?.specs ? [data.SpecsRevisionController_getOne.specs] : prev;
    setLeftRevisionData(prev => updateData(leftSpecsRevisionData, prev));
    setRightRevisionData(prev => updateData(rightSpecsRevisionData, prev));
    setIsEditorLoading(false);
  }, [leftSpecsRevisionData, rightSpecsRevisionData]);

  if (!revSpec) {
    return <Skeleton active loading />;
  }

  const getSpecsData = (data: { specs: any }[]) => arrToYamlString(data?.[0]?.specs || []);

  const comparisionData =
    showLastApplied &&
    (lastRevisionId ? (
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        {isEditorLoading ? (
          <Skeleton active loading />
        ) : (
          <DiffEditor height="90vh" language="yaml" original={getSpecsData(leftRevisionData)} modified={getSpecsData(rightRevisionData)} />
        )}
      </FullScreenEditor>
    ) : (
      <Alert showIcon type="warning" key="info" message="Kindly create a revision to compare them in the Editor" />
    ));

  const alertData = (
    <Alert
      showIcon
      type="info"
      key="info"
      message={`Revision: A snapshot of your application configuration, create a new revision anytime and apply it to test configurations or roll back to a previous one.`}
      action={
        <Space direction="horizontal">
          {showLastApplied && <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />}
          <Button type="primary" onClick={() => setShowLastApplied(!showLastApplied)}>
            {showLastApplied ? 'View timeline' : 'View editor'}
          </Button>
        </Space>
      }
    />
  );

  const selectRevision = () => {
    const items =
      revisions?.map(({ id, name }: iSpecsRevision) => {
        const itemID = id.toString();
        const labelData = (
          <Space direction="vertical">
            <Text strong> {name || 'Anonymous Revision'} </Text>
          </Space>
        );
        return { key: itemID, value: itemID, label: labelData, name: name };
      }) || [];

    const editorData = () => {
      const selectorData = () => {
        const handleOnChange = (setRevisionId: isetRevisionId) => value => {
          setRevisionId(Number(value));
          setIsEditorLoading(true);
        };
        return [
          { defaultValue: lastRevisionId.toString(), onChange: handleOnChange(setLeftRevisionId), placeholder: 'Select a revision' },
          {
            defaultValue: lastRevisionId.toString(),
            onChange: handleOnChange(setRightRevisionId),
            placeholder: 'Select a revision to Compare',
            style: revisionWidth,
          },
        ];
      };
      const handleFilters = (input: string, option: { name: string }) => option?.name?.toLowerCase().includes(input.toLowerCase());
      return (
        <Space style={spaceWidth}>
          <Flex style={contentJustified}>
            {selectorData().map((selectProps, index) => (
              <Select key={index} {...selectProps} showSearch filterOption={handleFilters} options={items} suffixIcon={<DownOutlined />} />
            ))}
          </Flex>
        </Space>
      );
    };

    return showLastApplied && lastRevisionId && editorData();
  };

  const newRevision = <NewRevisionForm applyLater={true} deployment={deployment} onNewRevision={() => specsRevisions.refetch()} />;

  const timelineData = () => {
    const itemsData = [
      ...revisions.map(({ id, name, createdAt, description }: iSpecsRevision) => {
        const popTitle = `Apply revision ${name || 'without name'}?`;
        const popDescription = description || '';
        const handleOnConfirm = () => {
          setLeftRevisionId(id);
          SpecsRevisionController_applyRevision({ variables: { applicationId: deployment.id, revisionId: id } });
        };

        const revisionContent = (
          <Flex gap="middle" justify="space-between">
            <Text>
              Revision {name} is created at: {moment(Number(createdAt)).format('DD:MM:YYYY ~ HH:mm:ss A')}
            </Text>
            <Popconfirm title={popTitle} description={popDescription} onConfirm={handleOnConfirm}>
              <Button disabled={leftRevisionId === id} style={buttonBorder}>
                {leftRevisionId === id ? 'Applied' : 'Apply'}
              </Button>
            </Popconfirm>
          </Flex>
        );

        return { color: leftRevisionId === id ? 'green' : 'grey', children: revisionContent };
      }),
      { color: 'blue', children: newRevision },
    ];

    return (
      !showLastApplied && (
        <>
          <Text /> {revisions?.length ? <Timeline mode="left" reverse={true} items={itemsData} /> : <Text> No revisions yet: {newRevision} </Text>}
        </>
      )
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {alertData}
      {timelineData()}
      {selectRevision()}
      {comparisionData}
    </Space>
  );
};

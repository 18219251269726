import { Alert, Button, Divider, Skeleton, Space, Table, Tabs } from 'antd';
import gql from 'graphql-tag';
import { iRegionModel } from 'shared/deployment';
import { useAuthedMutation, useAuthedQuery } from 'utils/qlAuth';
import { ClusterSecurityCheckovReport } from './ClusterSecurityCheckovReport';
import { ClusterSecurityKubeBenchReport } from './ClusterSecurityKubeBenchReport';

export interface iClusterSecurityReportProps {
  region: iRegionModel;
}
export default function ClusterSecurityReport(props: iClusterSecurityReportProps) {
  const [runCheckovJob, runCheckovJobResult] = useAuthedMutation(gql`
    mutation ClusterSecurityController_runCheckovJob($regionId: Int!) {
      ClusterSecurityController_runCheckovJob(regionId: $regionId) {
        answer
      }
    }
  `);
  const [runKubeBenchJob, runKubeBenchJobResult] = useAuthedMutation(gql`
    mutation ClusterSecurityController_runKubeBenchJob($regionId: Int!) {
      ClusterSecurityController_runKubeBenchJob(regionId: $regionId) {
        answer
      }
    }
  `);

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Button onClick={() => runCheckovJob({ variables: { regionId: props.region.id } })}>Run Checkov</Button>
        <Button onClick={() => runKubeBenchJob({ variables: { regionId: props.region.id } })}>Run Kube-bench</Button>
      </Space>
      <Divider />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Checkov" key="1">
          <ClusterSecurityCheckovReport region={props.region} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kube-bench" key="2">
          <ClusterSecurityKubeBenchReport region={props.region} />
        </Tabs.TabPane>
      </Tabs>
    </Space>
  );
}

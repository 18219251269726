import { Skeleton, Table } from 'antd';
import gql from 'graphql-tag';
import { useAuthedQuery } from 'utils/qlAuth';
import { iClusterSecurityReportProps } from './ClusterSecurityReport';

export function ClusterSecurityCheckovReport(props: iClusterSecurityReportProps) {
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ClusterSecurityController_getLastCheckovReport($regionId: Int!) {
        ClusterSecurityController_getLastCheckovReport(regionId: $regionId) {
          answer
        }
      }
    `,
    { skip: !props?.region?.id, variables: { regionId: props.region.id } },
  );

  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }
  const lastReport = data?.ClusterSecurityController_getLastCheckovReport?.answer;

  const columns = [
    { title: 'classname', dataIndex: 'classname', key: 'classname' },
    { title: 'file', dataIndex: 'file', key: 'file' },
    { title: 'message', dataIndex: 'message', key: 'message' },
    { title: 'name', dataIndex: 'name', key: 'name' },
    { title: 'text', dataIndex: 'text', key: 'text' },
    { title: 'type', dataIndex: 'type', key: 'type' },
  ];
  return <Table columns={columns} dataSource={lastReport} />;
}

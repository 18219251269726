import React from 'react';
import { DeploymentVarWidget } from 'form-components/deploymentVarWidget';
import { JsonFormModalUI, iJsonFormModalUIProps } from './JsonFormModalUI';
import { Button, Card, Col, Input, InputNumber, Popover, Row, Select, Space, Tag, Typography } from 'antd';
import { EditOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { dockerInput, dockerWidth, selectWidth, spaceWidth } from 'utils/styles';
import { UnifieQuestionCircleIcon } from 'components/CustomIcons/CustomIcons';

interface iCommonFormUI {
  form: { name: any; children: any; help?: any; icon?: any; text?: any }[];
}

interface iCommonUIText {
  help?: any;
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  validator?: (v) => string;
  autoFixer?: (v) => string;
}

interface iCommonUINumber {
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  validator?: (v) => string;
}

interface iCommonUIDeploymentVarWidget {
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  dstType?: any;
  validator?: (v) => string;
}

interface iCommonUISelect {
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  options: { value: any; label: string | any }[];
  mode?: 'multiple' | 'tags';
}

const { Text } = Typography;
const { Option } = Select;

export const CommonFormUI = (props: iCommonFormUI) => (
  <>
    {props.form.map(({ icon, name, children, help, text }) => (
      <Space direction="vertical" style={spaceWidth}>
        <Card size="small" bordered={false}>
          <Row>
            <Col span={16}>
              <Space direction="vertical">
                <Space direction="horizontal">
                  {icon} {name}
                </Space>
                <Text type="secondary"> {text} </Text>
              </Space>
            </Col>
            <Col span={8}>
              {children}
              {help || null}
            </Col>
          </Row>
        </Card>
        <Text />
      </Space>
    ))}
  </>
);

export const autoFixer_emptyAsUndefined = (v: string) => {
  if (v === '') return undefined;
  return v;
};

export const CommonUI_text = (props: iCommonUIText) => {
  const [error, setError] = React.useState<string>('');
  const handleOnChange = v => {
    if (props.autoFixer) {
      v.target.value = props.autoFixer(v.target.value); // Auto fixer
    }
    if (props.validator) {
      const res = props.validator(v.target.value);
      if (res) {
        setError(res);
        return;
      } else {
        setError(null);
      }
    }
    props.onChange(`${props.path}.${props.propertyName}`, v.target.value);
  };
  const inputAddon = props.help ? (
    <Popover placement="topLeft" content={props.help}>
      <UnifieQuestionCircleIcon />
    </Popover>
  ) : null;
  return (
    <>
      <Input value={props.value} style={spaceWidth} status={error ? 'error' : null} onChange={handleOnChange} addonAfter={inputAddon} />
      {error ? (
        <>
          <br />
          {error}
        </>
      ) : null}
    </>
  );
};

export const CommonUI_number = (props: iCommonUINumber) => {
  const [error, setError] = React.useState<string>('');
  const handleOnChange = v => {
    if (props.validator) {
      const res = props.validator(v);
      if (res) {
        setError(res);
        return;
      } else {
        setError(null);
      }
    }
    props.onChange(`${props.path}.${props.propertyName}`, Number(v));
  };
  return (
    <>
      <InputNumber value={props.value} style={spaceWidth} status={error ? 'error' : null} onChange={handleOnChange} />
      {error ? (
        <>
          <br />
          {error}
        </>
      ) : null}
    </>
  );
};

export const CommonUI_DeploymentVarWidget = (props: iCommonUIDeploymentVarWidget) => {
  const handleOnBlur = function (id: string, value: any): void {
    console.log(`On Blur = ID: ${id} | Value: ${value}`);
  };
  const handleOnChange = v => props.onChange(`${props.path}.${props.propertyName}`, v);

  const handleOnFocus = function (id: string, value: any): void {
    console.log(`On Focus = ID: ${id} | Value: ${value}`);
  };
  return (
    <div style={dockerInput}>
      <DeploymentVarWidget
        id={'1'}
        name={props.propertyName}
        schema={{ type: props.dstType, validator: props.validator }}
        value={props.value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        label={''}
        options={undefined}
        registry={undefined}
      />
    </div>
  );
};

export const CommonUI_JsonFormModalUI = (props: iJsonFormModalUIProps) => {
  let hasData = false;
  if (Array.isArray(props.formData)) {
    hasData = props.formData.length > 0;
  } else if (typeof props.formData === 'object' && props.formData !== null) {
    hasData = Object.keys(props.formData).length > 0;
  } else {
    hasData = !!props.formData;
  }
  return (
    <Space>
      <Button onClick={() => JsonFormModalUI(props)}>
        <EditOutlined />
      </Button>
      <Tag color={hasData ? 'green' : 'red'}> {hasData ? 'Configured' : 'Not configured'} </Tag>
    </Space>
  );
};

export const CommonUI_select = (props: iCommonUISelect) => {
  const handleOnChange = v => props.onChange(`${props.path}.${props.propertyName}`, v);
  return (
    <Select mode={props.mode || undefined} value={props.value} onChange={handleOnChange} style={{ ...selectWidth, ...spaceWidth }}>
      {props.options.map((v, i) => (
        <Option key={i} value={v.value}>
          {v.label}
        </Option>
      ))}
    </Select>
  );
};

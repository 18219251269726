import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iUserModel } from 'shared/user';
import { useHistory } from 'react-router-dom';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { CustomWidgets } from 'form-components';
import { UserRolesList } from 'shared/UserRolesPermission';
import { useParams } from 'react-router-dom';
import { Button, Modal, Skeleton, Space, notification, Typography, Tabs, Alert, TabsProps } from 'antd';
import { CalendarFilled, DeleteFilled, ExclamationCircleOutlined, KeyOutlined } from '@ant-design/icons';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { formatUserName } from 'utils/nameFormat';
import { UserApiKeys } from './ApiKeys';
import { PlusOutlined } from '@ant-design/icons/lib';
import { UpdateUserForm } from './UpdateUserForm';

const { Text } = Typography;

export interface iUserPageProps {
  userId: number;
}

export const UserPage = (props: iUserPageProps) => {
  const params: any = useParams();
  const userId = Number(params?.userId ? params?.userId : props.userId);
  const history = useHistory();
  const [userData, err, load] = useApiQuery(() => usersService.getUser(userId), [userId]);

  if (!userData || err || load) return <Skeleton active={true} loading={true} />;

  const user: iUserModel = userData;

  const deleteUserGeneralLabel = () => (
    <Text>
      <CalendarFilled /> General
    </Text>
  );

  const deleteUserdangerZoneLabel = () => (
    <Text type="danger">
      <DeleteFilled /> Danger Zone
    </Text>
  );

  const modalContent = (
    <Space direction="vertical">
      <Text>
        You will be deleting the User: <Text strong> {formatUserName(user.email)} </Text>
      </Text>
      <Text strong> {userData?.name || ''} </Text>
    </Space>
  );

  const deleteUserdangerZoneChildren = () => {
    const handleOnClickAddUsers = async () => {
      const handleOnOk = async () => {
        await usersService.deleteUser(userId);
        history.push('/users');
      };
      Modal.confirm({
        title: 'Are you sure you want to Delete this User ?',
        icon: <ExclamationCircleOutlined />,
        content: modalContent,
        onOk: handleOnOk,
      });
    };
    return (
      <Space direction="vertical" style={spaceWidth}>
        <Alert showIcon type="error" message="This action is irreversible, and deletion will lead to the deletion of User." />
        <Text />
        <Button type="primary" danger style={buttonBorder} onClick={handleOnClickAddUsers}>
          Delete User
        </Button>
      </Space>
    );
  };

  const deleteUser: TabsProps['items'] = [
    // { key: 'deleteUserGeneral', label: deleteUserGeneralLabel(), children: deleteUserGeneralChildren() },
    { key: 'deleteUserGeneral', label: deleteUserGeneralLabel(), children: <UpdateUserForm user={user} /> },
    {
      key: 'api',
      label: (
        <Text>
          <KeyOutlined /> API keys
        </Text>
      ),
      children: <UserApiKeys userId={userId} />,
    },
    { key: 'deleteUserdangerZone', label: deleteUserdangerZoneLabel(), children: deleteUserdangerZoneChildren() },
  ];

  return <Tabs defaultActiveKey="deleteUserGeneral" items={deleteUser} />;
};

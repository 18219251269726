import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { useHistory, useParams } from 'react-router-dom';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { Col, Skeleton, Tabs } from 'antd';
import { clusterHeight } from 'utils/styles';
import { stats_availability_long_time, stats_availability_day_time, stats_availability_hour_time } from 'components/Tenant/stats_availability';
import { GlobalStatus } from './GlobalStatus';

const { TabPane } = Tabs;

export const GlobalMonitoringPage = () => {
  // @ts-ignore
  const { tabName } = useParams();
  const history = useHistory();
  const handleTabClick = (key: string) => history.push(`/monitoring/${key}`);

  const tabsStats = [
    { tab: 'Availability', key: 'availability-long-time', stats: stats_availability_long_time },
    { tab: 'Availability (Day)', key: 'availability-day', stats: stats_availability_day_time },
    { tab: 'Availability (Hour)', key: 'availability-hour', stats: stats_availability_hour_time },
  ];

  if (!tabName) {
    history.push(`/monitoring/status`);
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <Tabs defaultActiveKey={tabName || 'status'} onChange={handleTabClick}>
      <TabPane tab={`Status`} key={`status`}>
        <GlobalStatus />
      </TabPane>
      {tabsStats.map(({ tab, key, stats }) => (
        <TabPane tab={tab} key={key}>
          {stats.map(({ span, id, name, vizState }, index) => (
            <Col span={span || 24} key={id || index} style={clusterHeight}>
              <DashboardItem title={name}>
                <ChartRenderer vizState={vizState} />
              </DashboardItem>
            </Col>
          ))}
        </TabPane>
      ))}
    </Tabs>
  );
};

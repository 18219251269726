import { useEffect, useRef, useState } from 'react';
import Sider from 'antd/es/layout/Sider';
import NhHeader from 'components/SharedComponents/Header/Header';
import NhBreadcrumb from 'components/SharedComponents/Header/Breadcrumb';
import { hasSideNavBarLevel2, SideNavBarLevel1, SideNavBarLevel2 } from '../components/SharedComponents/SideNav/SideNavBar';
import { goToLogin, goToLoginLink, useCubeJsApi, useUser } from 'utils/common';
import { CubeProvider } from '@cubejs-client/react';
import { authService } from 'services/auth.service';
import { LoginOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { publicRoutes } from 'routes/RouteConfig';
import { Content } from 'antd/es/layout/layout';
import { Provider } from 'react-redux';
import { store } from 'services/store';
import { TitleUI } from './TitleUI';
import { WidgetArea } from './WidgetArea';
import { BrandLogoMain, excludedPaths } from 'components/SharedComponents/AuthComp/AuthComp';
import { Button, Card, Flex, FloatButton, Layout, Modal, Result, Skeleton, Space, Typography } from 'antd';
import {
  buttonWidth,
  headerStyle,
  layoutStyle,
  containerStyle,
  divStyle,
  contentStyle,
  skeletonPadding,
  spaceWidth,
  flexLayoutGrowLeft,
  growNorShrink,
} from 'utils/styles';
import { useLocation } from 'react-router-dom';
import { TipLeftTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { BackTop } = FloatButton;
const { Text } = Typography;

const AdminLayout = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [cubeApi, error, loading] = useCubeJsApi();
  const [isAuth, setAuth] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const user = useUser();
  const showSideNavBarLevel2 = hasSideNavBarLevel2(user);
  const publicUrls = (publicRoutes || []).map(({ path }) => `#${path}`);
  const { pathname } = useLocation();
  const location = window.location.hash;

  useEffect(() => {
    const intervalID = setInterval(() => {
      authService.checkAuth().then(res => {
        res ? setAuth(true) : !publicUrls.includes(location) && location.indexOf('#/login/email/') === -1 ? setAuth(false) : null;
      });
    }, 300000);
    return () => clearInterval(intervalID);
  }, []);

  const loader = () => (
    <div style={skeletonPadding}>
      <Skeleton active={true} loading={true} />
    </div>
  );

  const handleOk = () => goToLogin();

  const loginButton = (
    <a href={`/#${goToLoginLink()}`} onClick={handleOk}>
      <Button type="primary" style={buttonWidth}>
        Login
      </Button>
    </a>
  );

  const authTimeout = () => (
    <Modal key="modal" open={isAuth === false} onOk={handleOk} onCancel={() => setAuth(true)} title={null} footer={null}>
      <Result icon={<LoginOutlined />} title="Your session has ended." subTitle="Please login again." extra={loginButton} />
    </Modal>
  );

  const header = () => (
    <Card size="small" bordered={false} style={headerStyle}>
      <Flex gap="middle" justify="space-between">
        <BrandLogoMain />
        <NhHeader />
      </Flex>
    </Card>
  );

  const topIcon = (
    <FloatButton.Group shape="circle" style={{ insetInlineEnd: 20, marginBottom: '30px' }}>
      <TipLeftTop tip="Back to Top">
        <BackTop type="primary" target={() => containerRef.current} />
      </TipLeftTop>
      <TipLeftTop
        tip={
          <Space direction="vertical">
            <a href="mailto:support@unifie.cloud" style={{ color: '#fff' }}>
              ❓ Any help: support@unifie.cloud
            </a>
            <a href="https://discord.gg/UeEr36eteV" target="_blank" style={{ color: '#fff' }}>
              🤩 Discord community
            </a>
          </Space>
        }
      >
        <a href="mailto:support@unifie.cloud">
          <FloatButton icon={<QuestionCircleOutlined />} />
        </a>
      </TipLeftTop>
    </FloatButton.Group>
  );

  const info = () =>
    !excludedPaths.includes(pathname) ? (
      <Flex justify="space_between">
        <div style={flexLayoutGrowLeft}>
          <Space direction="vertical">
            <NhBreadcrumb />
            <Text />
            <TitleUI />
          </Space>
        </div>
        <div style={growNorShrink}>
          <WidgetArea />
        </div>
      </Flex>
    ) : null;

  const content = () => (
    <Layout hasSider={showSideNavBarLevel2} style={layoutStyle}>
      {showSideNavBarLevel2 && (
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
          <SideNavBarLevel2 />
        </Sider>
      )}
      <Layout>
        <div ref={containerRef} style={containerStyle}>
          <div style={divStyle}>
            <Content style={contentStyle}>
              <Space direction="vertical" style={spaceWidth}>
                {info()}
                {children}
              </Space>
            </Content>
          </div>
        </div>
        {topIcon}
      </Layout>
    </Layout>
  );

  const appContent = () => (
    <Provider store={store}>
      <CubeProvider cubejsApi={cubeApi}>
        {authTimeout()}
        {header()}
        <SideNavBarLevel1 />
        {content()}
      </CubeProvider>
    </Provider>
  );

  return loading ? loader() : (error && goToLogin(), error ? loader() : appContent());
};

export default AdminLayout;

import { Alert, Skeleton, Space } from 'antd';
import gql from 'graphql-tag';
import Markdown from 'react-markdown';
import { usersService } from 'services/users.service';
import { iMyUserData } from 'shared/user';
import { Designation } from 'shared/userDesignations';
import { useApiQuery, useUser } from 'utils/common';
import { useAuthedQuery } from 'utils/qlAuth';
import { spaceWidth } from 'utils/styles';

const UserDesignationInfo = () => {
  const user: iMyUserData = useUser();
  const [userData, err, load] = useApiQuery(() => usersService.getUser(user.id));
  if (!userData || userData.designation !== Designation.DEFAULT) return null;

  return (
    <>
      <Alert
        message="The role is set to default. Please set your designation in your profile to get more personalized suggestions."
        type="warning"
        closable
      />
      <br />
    </>
  );
};

export const EventAiSolution = (props: { event: any; regionId: number }) => {
  const qRecommendation = useAuthedQuery(
    gql`
      query TenantEventsController_getEventRecommendations($event: JSON!, $regionId: Int!) {
        TenantEventsController_getEventRecommendations(event: $event, regionId: $regionId) {
          answer
        }
      }
    `,
    { variables: { regionId: Number(props.regionId), event: props.event } },
  );

  if (qRecommendation.loading) {
    return (
      <>
        <Space direction="vertical" size="large" style={spaceWidth}>
          <Alert message="Generating an answer. This may take a few moments. Please wait patiently." type="warning" closable />
          <UserDesignationInfo />
          <Skeleton active={true} loading={true} />
        </Space>
      </>
    );
  }

  const text = qRecommendation.data?.TenantEventsController_getEventRecommendations?.answer;

  return (
    <Space direction="vertical">
      <Alert message="This response was generated by AI. Please review carefully." type="warning" closable />
      <UserDesignationInfo />
      <Markdown>{text}</Markdown>
    </Space>
  );
};

import gql from 'graphql-tag';
import _ from 'lodash';

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { getUrlHashParams, useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { authService } from 'services/auth.service';
import { InfoCircleOutlined } from '@ant-design/icons';
import { absolutely, spaceWidth, templateMargin } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipLeftTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { Row, Col, Typography, Button, Modal, Skeleton, Tag, Card, Space, Menu, Result, Input, Flex } from 'antd';
import type { MenuProps } from 'antd';
import { iProject } from 'interface/project';

const { Search } = Input;
type MenuItem = Required<MenuProps>['items'][number];

interface iExistingContent {
  name: any;
  id: string;
  title: string;
}

interface iTemplateSelectionList {
  preSelectedProjectId?: number;
  onSelect: any;
  selectBtnName: string;
  selectBtnTooltip: string;
  category: string;
}

interface iTemplateDataElem {
  tags: any[];
  id: any;
  title: any;
  logo: string;
  description: string;
  name?: string;
}

const { Text } = Typography;

function getExtraConfings() {
  let conf = getUrlHashParams();
  if (conf) {
    try {
      conf = JSON.parse(decodeURIComponent(conf.conf));
    } catch (e) {
      console.log(`TemplateSelectionList error:`, e);
      return {};
    }
  }
  console.log(`conf`, conf);
  return conf;
}

export function TemplateSelectionList(props: iTemplateSelectionList) {
  const { selectBtnTooltip, onSelect, selectBtnName } = props;
  const [selectedMenuKey, setSelectedMenuKey] = useState(props.category);
  const [searchQuery, setSearchQuery] = useState('');

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
          tenantId
        }
      }
    `,
    {},
  );

  const handleDebouncedSearch = _.debounce((value: string) => {
    setSearchQuery(value);
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleDebouncedSearch(value);
  };

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }
  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }

  let projectList = [];
  if (selectedMenuKey == 'own') {
    projectList = data?.ProjectController_getProjectList;
  } else {
    projectList = data?.PreconfiguredProjectsController_getPreconfiguredProjects;
  }

  const filteredTemplates = projectList.filter((template: iTemplateDataElem) => {
    const { name, id } = template;
    const isMatchingName = name.toLowerCase().includes(searchQuery.toLowerCase()) || id.toString().includes(searchQuery);
    return isMatchingName;
  });

  const searchBar = () => (
    <Search placeholder="Search using template name or ID" data-qa="search-input" enterButton allowClear onChange={handleSearchChange} />
  );

  const templateData = (
    <Space direction="vertical" style={spaceWidth} data-qa="DeploymentFromTemplate.tsx">
      <Col span={10}> {searchBar()} </Col>
      <Row gutter={[16, 16]}>
        {/*{data?.PreconfiguredProjectsController_getPreconfiguredProjects.filter(p => {*/}
        {filteredTemplates.filter(p => {
          if (selectedMenuKey == 'all' || selectedMenuKey == 'own') {
            return true;
          }
          if (p.tags) {
            return p.tags.includes(selectedMenuKey);
          }
          return false;
        }).length > 0 ? (
          filteredTemplates
            .filter(p => {
              if (selectedMenuKey == 'all' || selectedMenuKey == 'own') {
                return true;
              }
              if (p.tags) {
                return (p.tags || []).find(t => t.toLowerCase() === selectedMenuKey.toLowerCase());
              }
              return false;
            })
            .map((elem: iTemplateDataElem) => {
              const deployButton = (
                <TipLeftTop tip={selectBtnTooltip}>
                  <Button type="primary" data-qa="PreconfiguredProjects-Deploy-btn" onClick={() => onSelect(elem)}>
                    {selectBtnName}
                  </Button>
                </TipLeftTop>
              );

              // const deployTags = (
              //   <Space direction="horizontal">
              //     {elem.tags &&
              //       elem.tags.map(tag => (
              //         <Tag color="green" key={tag}>
              //           {tag}
              //         </Tag>
              //       ))}
              //   </Space>
              // );
              const deployTags = (
                <Flex wrap="wrap" gap="small">
                  {elem.tags &&
                    elem.tags.map(tag => (
                      <Tag color="green" key={tag}>
                        {tag}
                      </Tag>
                    ))}
                </Flex>
              );

              return (
                <Col key={elem.id} xs={24} sm={12} md={8}>
                  <Card size="small" type="inner" bordered={false} title={elem.title} extra={deployButton} style={templateMargin}>
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        <LogoAvatar logo={elem.logo} name={elem.title} />
                        {/*<Text type="secondary">{elem.description}</Text>*/}
                        <Text type="secondary">{elem.description.length > 180 ? `${elem.description.slice(0, 180)}...` : elem.description}</Text>
                      </Space>
                      <Text /> <Text /> <Text />
                      <Col style={{ height: '55px', overflow: 'hidden' }}>{deployTags}</Col>
                      {/*<Space direction="horizontal">*/}
                      {/*  <Space style={absolutely}>{deployTags}</Space>*/}
                      {/*</Space>*/}
                    </Space>
                  </Card>
                </Col>
              );
            })
        ) : (
          <Result style={{ margin: 'auto' }} title="There are no projects in this category" />
        )}
      </Row>
    </Space>
  );

  const items: MenuItem[] = [
    { key: 'own', icon: <InfoCircleOutlined />, label: 'Own' },
    { key: 'all', icon: <InfoCircleOutlined />, label: 'All' },
    { key: 'personal', icon: <InfoCircleOutlined />, label: 'Personal' },
    // { key: 'management', icon: <InfoCircleOutlined />, label: 'Content Management' },
    { key: 'devops', icon: <InfoCircleOutlined />, label: 'DevOps Tools' },
    { key: 'database', icon: <InfoCircleOutlined />, label: 'Database Management' },
    { key: 'monitoring', icon: <InfoCircleOutlined />, label: 'Monitoring & Logging' },
    // { key: 'media', icon: <InfoCircleOutlined />, label: 'Media & Entertainment' },
    { key: 'security', icon: <InfoCircleOutlined />, label: 'Security' },
    { key: 'ai', icon: <InfoCircleOutlined />, label: 'Artificial intelligent' },
  ];
  const onClick: MenuProps['onClick'] = e => setSelectedMenuKey(e.key);
  // const menuStyle: any = { width: 300, position: 'relative', left: -24, marginTop: -300, borderRight: '1px solid transparent' };
  const menuStyle: any = { width: 300, position: 'relative', left: -24, borderRight: '1px solid transparent' };

  return (
    <>
      <Row>
        <Col flex="300px">
          <Menu
            mode="inline"
            onClick={onClick}
            defaultSelectedKeys={[props.category]}
            defaultOpenKeys={[props.category]}
            items={items}
            style={menuStyle}
          />
        </Col>
        <Col flex="calc(100% - 300px)">{templateData} </Col>
      </Row>
    </>
  );
}

interface iDeploymentFromTemplate {
  projectId?: number;
  category?: string;
  preFillValues?: {
    description: string;
    name: string;
    tags: any[];
    regionId: number;
  };
  selected?: iProject | null;
}

export const CreateApplicationFromTemplate = async (project: iProjectModel, user, preFillValues, history) => {
  debugger;
  if (user.tenant === project?.tenantId && 0) {
    // Copy from the same tenant (own project)
    const result = await authService.getApolloClient().query({
      query: gql`
        mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
          DeploymentsController_createDeployment(projectId: $projectId, dpDeployFlow: $dpDeployFlow, deploymentName: $name, deploymentTags: $tags) {
            id
          }
        }
      `,
      variables: {
        projectId: project.id,
        dpDeployFlow: 'from-copy',
        regionId: preFillValues?.regionId || null,
        description: preFillValues?.description || ``,
        name: preFillValues?.name || `New application ${project.name}`,
        tags: preFillValues?.tags || [],
      },
    });
    const newDeploymentId = result?.data?.DeploymentsController_createDeployment?.id;
    if (newDeploymentId) {
      history.push(`/app/${newDeploymentId}/configuration/settings/general`);
    }

    return;
  }

  /**
   * Can solve: https://nanoheal.atlassian.net/browse/DP-635
   * And if it returns a project, ask user if they want to use that project instead of creating a new one.
   * It needed for avoinding duplicating projects. */

  const result = await authService.getApolloClient().query({
    query: gql`
      query ProjectController_findSimilarProjects($projectId: Int!) {
        ProjectController_findSimilarProjects(projectId: $projectId) {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    variables: { projectId: project.id },
  });

  const similarProjects = result?.data?.ProjectController_findSimilarProjects || [];

  if (similarProjects.length > 0) {
    Modal.confirm({
      title: 'Use existing template',
      icon: <InfoCircleOutlined />,
      content: (
        <>
          A similar template already exists. Do you want to use the existing template or create a new copy?
          {similarProjects.map(({ id, name, title }: iExistingContent) => {
            return (
              <Button
                onClick={async e => {
                  const result = await authService.getApolloClient().query({
                    query: gql`
                      mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
                        DeploymentsController_createDeployment(
                          projectId: $projectId
                          dpDeployFlow: $dpDeployFlow
                          deploymentName: $name
                          deploymentTags: $tags
                        ) {
                          id
                        }
                      }
                    `,
                    variables: {
                      projectId: id,
                      dpDeployFlow: 'from-copy',
                      regionId: preFillValues?.regionId || null,
                      description: preFillValues?.description || ``,
                      name: preFillValues?.name || `New application ${name}`,
                      tags: preFillValues?.tags || [],
                    },
                  });
                  const newDeploymentId = result?.data?.DeploymentsController_createDeployment?.id;
                  if (newDeploymentId) {
                    history.push(`/app/${newDeploymentId}/configuration/settings/general`);
                  }
                }}
              >
                Copy from ({id}) {title}
              </Button>
            );
          })}
        </>
      ),
      cancelText: 'Cancel',
      okText: 'Create a new copy',
      async onOk() {
        const result = await authService.getApolloClient().query({
          query: gql`
            mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
              DeploymentsController_createDeployment(
                projectId: $projectId
                dpDeployFlow: $dpDeployFlow
                deploymentName: $name
                deploymentTags: $tags
              ) {
                id
              }
            }
          `,
          variables: {
            projectId: project.id,
            dpDeployFlow: 'from-copy',
            regionId: preFillValues?.regionId || null,
            description: preFillValues?.description || ``,
            name: preFillValues?.name || `New application ${project.name}`,
            tags: preFillValues?.tags || [],
          },
        });
        const newDeploymentId = result?.data?.DeploymentsController_createDeployment?.id;
        if (newDeploymentId) {
          history.push(`/app/${newDeploymentId}/configuration/settings/general`);
        }
      },
    });

    return;
  }
  Modal.confirm({
    title: 'Creating a application from a template',
    icon: <InfoCircleOutlined />,
    content: (
      <Space direction="vertical">
        <Text> The project template will be automatically copied from the general template library and added to your own project list. </Text>
      </Space>
    ),
    async onOk() {
      const result = await authService.getApolloClient().query({
        query: gql`
          mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
            DeploymentsController_createDeployment(projectId: $projectId, dpDeployFlow: $dpDeployFlow, deploymentName: $name, deploymentTags: $tags) {
              id
            }
          }
        `,
        variables: {
          projectId: project.id,
          dpDeployFlow: 'from-template',
          regionId: preFillValues?.regionId || null,
          description: preFillValues?.description || ``,
          name: preFillValues?.name || `New application ${project.name}`,
          tags: preFillValues?.tags || [],
        },
      });
      const newDeploymentId = result?.data?.DeploymentsController_createDeployment?.id;
      if (newDeploymentId) {
        history.push(`/app/${newDeploymentId}/configuration/settings/general`);
      }
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

/** @returns Create deployment from (/new route) */
export default function DeploymentFromTemplate(props: iDeploymentFromTemplate) {
  // const params: any = useParams();
  const history = useHistory();
  const preSelectedProject = Number(props?.projectId) || null;
  const user = useUser();

  return (
    <TemplateSelectionList
      preSelectedProjectId={preSelectedProject}
      category={props.category}
      onSelect={async (project: iProjectModel) => {
        await CreateApplicationFromTemplate(project, user, props.preFillValues, history);
      }}
      selectBtnName="Deploy"
      selectBtnTooltip="You can deploy pre-configured ready-made project. It allow to deploy any software in your cluster in several minutes."
    />
  );
}

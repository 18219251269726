import { EditOutlined, ExperimentOutlined } from '@ant-design/icons';
import { Drawer, Tabs, Tag, Typography } from 'antd';
import UnifieEditor from 'components/CustomComponents/CustomComponents';
import moment from 'moment';
import { useState } from 'react';
import { arrToYamlString } from 'shared/yaml';
import { EventAiSolution } from './EventAiSolution';
import { iRegionModel } from 'shared/deployment';

export const EventsActionDrawer = (props: {
  record: any;
  eventsList: any[];
  index: number;
  showCluster: boolean;
  showNamespace: boolean;
  regionId: number;
}) => {
  const event = props.eventsList[props.index];

  const eventMessage = event.message;
  const eventReason = event.reason;
  const eventCount = event.count;
  const eventFirstTimestamp = moment(event.firstTimestamp).format('YYYY-MM-DD HH:mm:ss');
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const regionId: number = event?.region?.id || props.regionId;
  const eventYaml = { ...event };
  delete eventYaml.region;

  return (
    <>
      <EditOutlined onClick={showDrawer} />
      <Drawer title={eventReason} onClose={onClose} open={open} width={`80%`}>
        <Typography.Title level={4}> {eventMessage}</Typography.Title>
        <Tag>Event count: {eventCount}</Tag> <Tag>{eventFirstTimestamp}</Tag>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Event deteils" key="1">
            <UnifieEditor height={`calc(100vw - 200px)`} value={arrToYamlString([eventYaml])} language="yaml" />
          </Tabs.TabPane>
          {regionId && (
            <Tabs.TabPane
              tab={
                <>
                  <ExperimentOutlined /> How to fix?
                </>
              }
              key="2"
            >
              <EventAiSolution event={event} regionId={regionId} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Drawer>
    </>
  );
};

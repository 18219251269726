import { Skeleton } from 'antd';
import gql from 'graphql-tag';
import { useAuthedQuery } from 'utils/qlAuth';
import { EventsTable } from './EventRenders/EventsTable';

export const GlobalStatus = () => {
  const qRegions = useAuthedQuery(gql`
    query TenantEventsController_getClustersState {
      TenantEventsController_getClustersState {
        states
      }
    }
  `);

  if (qRegions.loading) {
    return <Skeleton active={true} loading={true}></Skeleton>;
  }

  const states: any[] = qRegions.data?.TenantEventsController_getClustersState.states || [];
  const allEvents = states.map(state => {
    return (state.answer.events || []).map((event: any) => {
      return {
        ...event,
        region: state.region,
      };
    });
  });
  const allEventsFlat = allEvents.flat();

  return (
    <div>
      <h1>Events from all clusters</h1>
      {/* {states.map((state, ix) => (
        <div key={state.region.id}>
          <Space>
            {state.region.id}
            {state.region.name}
            {state.region.title}
            {state.region.cloudProvider}
            {state.region.kubernetesVersion}
            {state.region.regionName}
            {!state.error ? <span style={{ color: 'green' }}>OK</span> : <span style={{ color: 'red' }}>ERROR {JSON.stringify(state.error)}</span>}
          </Space>
        </div>
      ))} */}
      <EventsTable
        regionId={null}
        showNamespace={true}
        showCluster={true}
        eventsList={allEventsFlat}
        refetch={qRegions.refetch}
        loading={qRegions.loading}
        error={qRegions.error}
      />
    </div>
  );
};

export enum Designation {
  DEFAULT = 'default',
  DEVOPS_ENGINEER = 'DevOps Engineer',
  SRE = 'Site Reliability Engineer (SRE)',
  PLATFORM_ENGINEER = 'Platform Engineer',
  CLOUD_ENGINEER = 'Cloud Engineer',
  KUBERNETES_ADMIN = 'Kubernetes Administrator',
  SOFTWARE_DEVELOPER = 'Software Developer',
  SECURITY_ENGINEER = 'Security Engineer',
  QA_ENGINEER = 'QA Engineer',
  PRODUCT_MANAGER = 'Product Manager',
  ENGINEERING_MANAGER = 'Engineering Manager',
  SUPPORT_OPERATIONS = 'Support / Operations Team',
}

export const DESIGNATION_LIST = Object.values(Designation);

import { Button, Popconfirm } from 'antd';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { iRegionModel } from '../../../shared/deployment';

export const ClusterPopDestroyBytton = (props: { region: iRegionModel; refetchJobList: () => void }) => {
  const [destroyPlanJobFunction, destroyPlanJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newDestroyPlanJob($regionId: Int!) {
      JobsController_newDestroyPlanJob(regionId: $regionId) {
        id
      }
    }
  `);
  useEffect(() => {
    props.refetchJobList();
  }, [destroyPlanJobRes]);
  const popDestroyOnConfirm = async e => {
    try {
      await destroyPlanJobFunction({ variables: { regionId: props.region.id } });
    } catch (e) {
      console.error(`Error in destroyPlanJobFunction`, e);
    }
  };
  return (
    <Popconfirm
      title="Cluster destroy plan"
      description={
        <>
          <ul>
            <li> This will create a destroy plan. </li>
            <li> It will create a new plan job and run the terraform plan command. </li>
            <li> It will not apply any changes to your infrastructure. </li>
          </ul>
        </>
      }
      onConfirm={popDestroyOnConfirm}
      okText="Continue"
      cancelText="Cancel"
    >
      <Button type="default">Destroy plan</Button>
    </Popconfirm>
  );
};

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iDeployment } from 'shared/deployment';
import { CustomWidgets } from 'form-components';
import { Col, Alert } from 'antd';
import { topsMargin } from 'utils/styles';
import { updateDeployment } from '../common';

export interface iIngressAnnotationFormProps {
  deployment: iDeployment;
}

const IngressAnotationsSchemaForm: any = {
  title: 'Ingress anotations',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      value: { type: 'string', title: 'Anotation value' },
      name: {
        type: 'string',
        title: 'Anotation name',
        examples: ['nginx.ingress.kubernetes.io/auth-type', 'nginx.ingress.kubernetes.io/auth-secret', 'nginx.ingress.kubernetes.io/auth-realm'],
      },
    },
  },
};

const schema = { FormSchema: IngressAnotationsSchemaForm, UISchema: { items: { value: { 'ui:widget': 'textarea' } } }, serviceVarConfig: {} };

/**
 * This component is used to create custom annotations for ingress object.
 * It can be usful for example to add authentication to ingress or customize other ingress annotations.
 * @param props
 * @returns
 * This code creates a form for the user to fill in ingress annotations.
 * The form has a submit button, which will call handleSubmit when pressed.
 * handleSubmit will make a request to the backend to update the ingress annotations.
 * This code also creates a message to show the user when they view the form.
 */
export const IngressAnnotationForm = (props: iIngressAnnotationFormProps) => {
  const { deployment } = props;

  const handleSubmit = async e => {
    const res = {};
    e.formData.forEach((v: { name: string | number; value: any }) => (res[v.name] = v.value));
    await updateDeployment(deployment.id, { ingressAnnotations: res });
  };

  return (
    <Col className="bg-white" span={24}>
      <Alert style={topsMargin} message={`Here you can configure ingress annotations for Ingress`} type="info" showIcon closable={false} />
      <Form
        // className="rjsfForm"
        widgets={CustomWidgets}
        formData={
          deployment.ingressAnnotations
            ? Object.keys(deployment.ingressAnnotations).map(key => ({ name: key, value: deployment.ingressAnnotations[key] }))
            : []
        }
        schema={schema.FormSchema}
        uiSchema={schema.UISchema}
        validator={validator}
        onChange={() => console.log('changed')}
        onSubmit={handleSubmit}
        onError={e => console.log('Error from submit: ', e)}
      />
    </Col>
  );
};

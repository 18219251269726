import { useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { Button, Input, Select, message, Alert, Typography, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { buttonBorder, buttonWidth, spaceWidth, storageStyle1, storageStyle2, storageStyle3, textCenter } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TipRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { updateDeployment } from '../common';

interface iStorageProps {
  deployment: iDeployment;
}

const { Title, Text } = Typography;
const { Option } = Select;

export const Storage_S3 = (props: iStorageProps) => {
  const deployments = props.deployment;
  const [select, setSelect] = useState('disk');
  const [env, setEnv] = useState('');

  const tooltipIcon = () => (
    <Text type="secondary">
      <InfoCircleOutlined />
    </Text>
  );

  const tooltipContent_BUCKET_NAME = (
    <TipRight tip="Can be empty. If it is empty then will be using one common bucket for all deployments. If you wish you can create manualy new S3 bucket and use it.">
      {tooltipIcon()}
    </TipRight>
  );

  const tooltipContent_ACCESS_KEY_ID = (
    <TipRight tip="Can be empty. It should be AWS API key with write access to S3 bucket `STORAGE_S3_BUCKET_NAME`"> {tooltipIcon()} </TipRight>
  );

  const tooltipContent_SECRET_ACCESS_KEY = (
    <TipRight tip="Can be empty. It should be AWS API secret key with write access to S3 bucket `STORAGE_S3_BUCKET_NAME`">{tooltipIcon()}</TipRight>
  );

  const envName = [
    { name: 'Storage S3 bucket name:', addonAfter: tooltipContent_BUCKET_NAME },
    { name: 'Storage S3 access key ID:', addonAfter: tooltipContent_ACCESS_KEY_ID },
    { name: 'Storage S3 secret access key:', addonAfter: tooltipContent_SECRET_ACCESS_KEY },
  ];

  const nameEnvStorage = 'STORAGE_TYPE';

  const handleChange = value => {
    setSelect(value);
    setEnv(
      // @ts-ignore
      env.filter((e: { name: string }) => e.name === nameEnvStorage)[0]?.name
        ? // @ts-ignore
          env.map((e: { name: string }) => (e.name === nameEnvStorage ? { name: nameEnvStorage, value } : e))
        : [...env, { name: nameEnvStorage, value }],
    );
  };

  useEffect(() => {
    const env = deployments ? Object.keys(deployments.env).map(k => ({ name: k, value: deployments.env[k] })) : '';
    // @ts-ignore
    setEnv(env);
    const storageType = env ? env.filter(e => e.name === nameEnvStorage)[0]?.value : undefined;
    setSelect(storageType || 'disk');
    // @ts-ignore
    !storageType ? setEnv([...env, { name: nameEnvStorage, value: 'disk' }]) : null;
  }, [deployments]);

  const onFinish = async values => {
    const sendEnv = {};
    let error = false;

    values.forEach((element: { name: string; value: any }) => {
      !element || (!element.name && !element.value)
        ? (error = true)
        : !element.name
        ? (message.error(`The value of the ${element.value} variable has no name`), (error = true))
        : !/^[A-Za-z0-9_]+$/.test(element.name)
        ? (message.error(`Variable name is invalid: ${element.name}`), (error = true))
        : (sendEnv[element.name] = element.value);
    });

    if (error) return;

    const res = await updateDeployment(props.deployment.id, { env: sendEnv });
    if (!res) return;
    deployments.env = res.data.env;
  };

  const deployementFreezed_A = () => (
    <Title level={5} style={textCenter}>
      This application is frozen, to edit the parameters, change the value of General {'->'} frozen to "false"
    </Title>
  );

  const alertMessage = (
    <Text>
      You can know&nbsp;
      <TipTop tip="Click here to know how to create new S3 bucket">
        <Text strong>
          <a href="https://gitlab.nanoheal.com/sourcecode/filestorageservice/-/blob/main/docs/s3.md" target="_blank">
            How to create new S3 bucket
          </a>
        </Text>
      </TipTop>
      &nbsp;and&nbsp;
      <TipTop tip="Click here to look at Docs for file storage service">
        <Text strong>
          <a href="https://gitlab.nanoheal.com/sourcecode/filestorageservice/-/blob/main/docs/s3.md" target="_blank">
            Docs for file storage service
          </a>
        </Text>
      </TipTop>
      .
    </Text>
  );

  const s3Select = () =>
    envName.map(({ addonAfter, name }) => <S3settings addonAfter={addonAfter} key={name} name={name} env={env} setEnv={setEnv} />);

  const deployementFreezed_B = () => (
    <BottomButtons>
      <Button type="primary" onClick={() => onFinish(env)} style={buttonBorder}>
        Save
      </Button>
    </BottomButtons>
  );

  const storageOptions = [
    { value: 'disk', label: 'Disk' },
    { value: 's3', label: 'S3' },
    { value: 's3_efs', label: 'S3 and EFS' },
  ];

  return (
    <Space direction="vertical" style={spaceWidth}>
      {deployments.useManualApply ? deployementFreezed_A() : ''}
      <Alert showIcon type="info" message={alertMessage} closable={false} />
      <Text />
      <Text />
      <Space direction="horizontal">
        <Text strong> Storage type: &nbsp; </Text>
        <Select disabled={deployments.useManualApply ? true : false} value={select} defaultValue={select} onChange={handleChange} style={buttonWidth}>
          {storageOptions.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Space>
      {select === 's3' || select === 's3_efs' ? s3Select() : ''}
      {deployments.useManualApply ? '' : deployementFreezed_B()}
    </Space>
  );
};

const S3settings = ({ name, env, setEnv, addonAfter }: any) => {
  const [state, setstate] = useState('');

  useEffect(() => {
    setstate(env.filter((e: { name: string }) => e.name === name)[0]?.value);
  }, []);

  useEffect(() => {
    env.filter((e: { name: string }) => e.name === name)[0]?.name
      ? setEnv(env.map((e: { name: string }) => (e.name === name ? { name, value: state } : e)))
      : setEnv([...env, { name, value: state }]);
  }, [state]);

  return (
    <div style={storageStyle1}>
      <div style={storageStyle2}> {name} </div>
      <Input value={state} addonAfter={addonAfter} onChange={e => setstate(e.currentTarget.value)} style={storageStyle3} />
    </div>
  );
};

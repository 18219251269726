import * as yaml from 'js-yaml';
import { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { WidgetProps } from '@rjsf/utils';
import { YamlSplitRegExp, prepareToYamlSplit } from 'shared/yaml';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { Typography } from 'antd';
import { redColor } from 'utils/styles';
import UnifieEditor from 'components/CustomComponents/CustomComponents';

const { Text } = Typography;

export const YamlWidget = (props: WidgetProps) => {
  let yamlStr = props.value;
  let isLastValueArray = null;
  const [yamlErrors, setYamlErrors] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    try {
      const value = JSON.parse(props.value);
      isLastValueArray = Array.isArray(value);
      yamlStr = yaml.dump(value, {});
      setYamlErrors(null);
    } catch (error) {
      setYamlErrors(error.message);
    }
  }, [props.value]);

  let timerId: any = null;
  const handleEditorChange = event => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      try {
        const value = yamlToJson(event);
        isLastValueArray ? props.onChange(JSON.stringify(value)) : props.onChange(JSON.stringify(value[0]));
        yamlErrors && setYamlErrors(null);
      } catch (error) {
        setYamlErrors(error.message);
      }
    }, 1000);
  };

  return (
    <>
      {yamlErrors && <div style={redColor}> {yamlErrors} </div>}
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <UnifieEditor
          height={isFullscreen ? '100vh' : 'calc(200px)'}
          onChange={handleEditorChange}
          width={`100%`}
          language={`yaml`}
          value={yamlStr}
          defaultValue=""
        />
      </FullScreenEditor>
    </>
  );
};

export function yamlToJson(content: string) {
  const returnData = prepareToYamlSplit(content)
    .split(YamlSplitRegExp)
    .map(part => yaml.load(part, { json: true }))
    .filter(v => !!v);
  return returnData;
}

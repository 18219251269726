import gql from 'graphql-tag';
import moment from 'moment';
import { useState, useMemo } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iDeployment } from 'shared/deployment';
import { Button, Col, Flex, Row, Select, Skeleton, Space, Table, Typography } from 'antd';
import { ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { spaceWidth } from 'utils/styles';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { EventsTable } from 'components/monitoring/EventRenders/EventsTable';

interface iDeploymentk8sEventsListProps {
  deployment: iDeployment | undefined;
  podName?: string;
}

const { Text } = Typography;
const { Option } = Select;

export function Deploymentk8sEventsList(props: iDeploymentk8sEventsListProps) {
  const [hovered, setHovered] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(props.podName || undefined);

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ApiAgentController_listNamespacedEvent($deploymentId: Int!) {
        ApiAgentController_listNamespacedEvent(deploymentId: $deploymentId) {
          status
          message
          events
        }
      }
    `,
    { skip: !props?.deployment?.id, variables: { deploymentId: props?.deployment?.id } },
  );

  return (
    <EventsTable
      regionId={props?.deployment?.region}
      showCluster={false}
      showNamespace={false}
      eventsList={data?.ApiAgentController_listNamespacedEvent?.events || []}
      refetch={refetch}
      loading={loading}
      error={error}
    />
  );
}

import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deploymentService } from '../../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { useApiQuery } from '../../../../utils/common';
import { Alert, Button, Result, Skeleton, Space, Tag, Typography } from 'antd';
import { bottomMargin, buttonSize, spaceWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import UnifieEditor from 'components/CustomComponents/CustomComponents';

const { Text } = Typography;

export interface iRoutesTabProps {
  deployment: iDeployment;
}

export const RoutesTab = (props: iRoutesTabProps) => {
  const [deploymentYAML, error, loading] = useApiQuery(() => deploymentService.debugUI_deploymentRoutes(Number(props.deployment.id)));
  const [isFullscreen, setIsFullscreen] = useState(false);

  const monitoringSpecQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getMonitoringSpec($deploymentId: Int!) {
        DeploymentsController_getMonitoringSpec(deploymentId: $deploymentId) {
          error
          monitoring
        }
      }
    `,
    { variables: { deploymentId: Number(props.deployment.id) } },
  );

  const monitoring = monitoringSpecQuery.data?.DeploymentsController_getMonitoringSpec?.monitoring;

  if (loading || !deploymentYAML || monitoringSpecQuery.loading) {
    return <Skeleton active={true} loading={true} />;
  }
  if (error) {
    return <Alert message="" description={`Can not load data: ${error}`} type="error" style={bottomMargin} />;
  }

  if (deploymentYAML.errorType === 'not-ready') {
    return <Result status="404" title="Not ready" subTitle={String(deploymentYAML.error)} style={bottomMargin} />;
  } else if (deploymentYAML.errorType === 'render-error') {
    return (
      <>
        <Alert message="" description={`Here you can see routes defenitions. It is read only.`} type="info" key={'info'} style={bottomMargin} />
        <Alert
          message=""
          description={`Error in yaml generation. Please check your application and project settings.`}
          type="error"
          key={'error'}
          style={bottomMargin}
        />
        <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
        <Text />
        <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
          <UnifieEditor
            height={isFullscreen ? '100vh' : 'calc(100vh - 215px)'}
            width={`100%`}
            language={`json`}
            value={deploymentYAML.error}
            defaultValue=""
          />
        </FullScreenEditor>
      </>
    );
  }

  if (deploymentYAML.routes === '') {
    return (
      <Space direction="vertical" style={spaceWidth}>
        <Alert
          showIcon
          message={
            <Space direction="vertical">
              <Text> This Application do not have any Routes defined. </Text>
            </Space>
          }
          type="info"
        />
      </Space>
    );
  }

  return (
    <>
      <Alert message="Here you can see routes defenitions. It is read only" type="info" key={'info'} style={bottomMargin} />
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      {monitoring?.length > 0 && (
        <Space direction="vertical">
          Monitoring endpoints:
          {monitoring.map((route, index) => {
            return (
              <Space direction="horizontal">
                <Tag>timeout {route.timeout}ms</Tag>
                <Text>
                  {route.host}
                  {route.path}
                </Text>
              </Space>
            );
          })}
          <Text />
        </Space>
      )}
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <UnifieEditor
          height={isFullscreen ? '100vh' : 'calc(100vh - 215px)'}
          width={`100%`}
          language={`yaml`}
          value={deploymentYAML.routes}
          defaultValue=""
        />
      </FullScreenEditor>
    </>
  );
};

import { useState } from 'react';
import Editor from '@monaco-editor/react';
import { WidgetProps } from '@rjsf/utils';
import { Typography } from 'antd';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import UnifieEditor from 'components/CustomComponents/CustomComponents';

const { Text } = Typography;

export const JSONWidget = (props: WidgetProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <>
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <UnifieEditor
          height={isFullscreen ? '100vh' : '200px'}
          onChange={event => props.onChange(event)}
          width={`800px`}
          language={`json`}
          value={props.value}
          defaultValue=""
        />
      </FullScreenEditor>
    </>
  );
};

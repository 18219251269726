import { Button, Checkbox, Col, Divider, Form, Input, notification, Row, Select } from 'antd';
import { UserRolesList, UserRolesListName } from 'shared/UserRolesPermission';
import { useEffect } from 'react';
import { usersService } from '../../services/users.service';
import { DESIGNATION_LIST } from 'shared/userDesignations';

const FormData = ({ initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const withPermission = [];
  const withoutPermission = [];

  UserRolesList.forEach(role => {
    if (role.includes('Permission')) {
      withPermission.push(role);
    } else {
      withoutPermission.push(role);
    }
  });
  console.log(UserRolesListName);
  console.log(withPermission);
  console.log(withoutPermission);

  const handleSave = async (e: any) => {
    const data = e;
    const res = await usersService.updateUser(initialValues.id, data);
    const notificationMessage = res.error ? `Error: ${res.error}` : 'Ready';
    notification[res.error ? 'error' : 'success']({ message: notificationMessage });
  };

  return (
    <Form form={form} onFinish={handleSave}>
      <Row align="middle" gutter={16} style={{ alignItems: 'center', marginBottom: '15px' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <strong>Name</strong>
          </div>
        </Col>
        <Col span={18}>
          <Form.Item key="name" name="name" rules={[{ required: true, message: 'Please enter your name' }]} style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" gutter={16} style={{ alignItems: 'center', marginBottom: '15px' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <strong>Email</strong>
          </div>
        </Col>
        <Col span={18}>
          <Form.Item key="email" name="email" rules={[{ required: true, message: 'Please enter your email' }]} style={{ marginBottom: 0 }}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" gutter={16} style={{ alignItems: 'center', marginBottom: '15px' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <strong>Password</strong>
          </div>
        </Col>
        <Col span={18}>
          <Form.Item key="password" name="password" style={{ marginBottom: 0 }}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" gutter={16} style={{ alignItems: 'center', marginBottom: '15px' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <strong>Designation</strong>
          </div>
        </Col>
        <Col span={18}>
          <Form.Item key="designation" name="designation" style={{ marginBottom: 0 }}>
            <Select
              placeholder="Select Designation"
              style={{ width: 250 }}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) => option && option.label.toLowerCase().includes(input.toLowerCase())}
              options={DESIGNATION_LIST.map(designation => ({
                label: designation,
                value: designation,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" gutter={16} style={{ alignItems: 'center' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '8px' }}>
            <strong>Blocked</strong>
            <Form.Item key="isBlocked" name="isBlocked" valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox />
            </Form.Item>
          </div>
        </Col>
        <Col span={18}></Col>
      </Row>
      <Row align="middle" gutter={16} style={{ alignItems: 'center' }}>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '8px' }}>
            <strong>Active</strong>
            <Form.Item key="isConfirmed" name="isConfirmed" valuePropName="checked" style={{ margin: 0 }}>
              <Checkbox />
            </Form.Item>
          </div>
        </Col>
        <Col span={18}></Col>
      </Row>
      <Row gutter={16} style={{ alignItems: 'flex-start' }}>
        <Col span={6}>
          <div style={{ marginBottom: '8px' }}>
            <strong>Roles:</strong>
          </div>
        </Col>
        <Col span={18}>
          <Form.Item key="roles" name="roles" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {Object.entries(withoutPermission).map(([key, value]) => (
                <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox value={value} />
                  <span style={{ marginLeft: '8px' }}>{value}</span>
                </div>
              ))}
              <Divider style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '0px', borderColor: '#bababa' }}>
                Permission
              </Divider>
              {Object.entries(withPermission).map(([key, value]) => (
                <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox value={value} />
                  <span style={{ marginLeft: '8px' }}>{UserRolesListName[value]}</span>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" style={{ marginRight: '0px' }}>
        Save
      </Button>
    </Form>
  );
};

export const UpdateUserForm = (props: any) => {
  return <FormData initialValues={props.user} />;
};

import { Button, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import gql from 'graphql-tag';
import { iRegionModel } from '../../../shared/deployment';

export const CluserPopKarpenterButton = (props: { region: iRegionModel; clusterAreReady: boolean; refetchJobList: () => void }) => {
  const initializeKarpenter = props.region.plugins.includes('Karpenter');
  const popInstalledDescription = (
    <ul>
      <li> Install Karpenter into you cluster </li>
      <li>
        <a href={`https://www.unifie.cloud/doc/docs/Cluster-configuration/Install-Karpenter/`} target="_blank">
          Read more about Karpenter
        </a>
      </li>
    </ul>
  );
  const [installKarpenter_Job, InstallKarpenter_JobId] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallKarpenter_Job($regionId: Int!) {
      JobsController_InstallKarpenter_Job(regionId: $regionId) {
        id
      }
    }
  `);
  const popInstalledOnConfirm = async e => {
    try {
      await installKarpenter_Job({ variables: { regionId: Number(props.region.id) } });
    } catch (e) {
      console.error(`Error in installKarpenter_Job`, e);
    }
  };
  useEffect(() => {
    props.refetchJobList();
  }, [InstallKarpenter_JobId]);
  return (
    <Popconfirm
      title="Install Karpenter"
      description={popInstalledDescription}
      onConfirm={popInstalledOnConfirm}
      okText="Continue"
      cancelText="Cancel"
    >
      <Button hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'} type={initializeKarpenter ? 'default' : 'primary'}>
        {initializeKarpenter ? 'Reinstall' : 'Install'} Karpenter
      </Button>
    </Popconfirm>
  );
};

import { Skeleton, Table } from 'antd';
import gql from 'graphql-tag';
import { iRegionModel } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';

export function ClusterSecurityKubeBenchReport(props: { region: iRegionModel }) {
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ClusterSecurityController_getLastKubeBenchReport($regionId: Int!) {
        ClusterSecurityController_getLastKubeBenchReport(regionId: $regionId) {
          answer
        }
      }
    `,
    { skip: !props?.region?.id, variables: { regionId: props.region.id } },
  );

  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }
  const lastReport = data?.ClusterSecurityController_getLastKubeBenchReport?.answer;

  return <pre>{JSON.stringify(lastReport, null, 2)}</pre>;
  // const columns = [
  //   { title: 'classname', dataIndex: 'classname', key: 'classname' },
  //   { title: 'file', dataIndex: 'file', key: 'file' },
  //   { title: 'message', dataIndex: 'message', key: 'message' },
  //   { title: 'name', dataIndex: 'name', key: 'name' },
  //   { title: 'text', dataIndex: 'text', key: 'text' },
  //   { title: 'type', dataIndex: 'type', key: 'type' },
  // ];
  // return <Table columns={columns} dataSource={lastReport} />;
}

import { notification, Button, Typography } from 'antd';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { iDeployment } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { buttonBorder } from 'utils/styles';

export const NewServicePreFill_k8s = (props: { serviceName: string; deployment: iDeployment; disabled: boolean }): ReactElement => {
  const history = useHistory();
  const handleCreateService = async (e: any) => {
    if (props.disabled) {
      return;
    }

    const name = props.serviceName;
    const projectId = props.deployment?.projectId;
    const newService: iCloudProjectServiceYamlSpecs = { name: name, uiType: `k8s` };
    const { error } = await projectService.setNewService(projectId, newService, props.deployment.id);
    if (error) {
      notification.error({ message: `Error: ${error}` });
      return;
    }

    notification.success({ message: 'Ready' });
    history.push(`/app/${props.deployment.id}/configuration/services/${name}/main`);
  };
  return (
    <>
      <Typography.Text> Kubernetes yaml file is a good choice for advanced users who want to have full control. </Typography.Text>
      <BottomButtons>
        <Button type="primary" disabled={props.disabled} onClick={handleCreateService} style={buttonBorder}>
          Save
        </Button>
      </BottomButtons>
    </>
  );
};

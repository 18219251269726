import moment from 'moment';
import { useState } from 'react';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { formatUserName } from 'utils/nameFormat';
import { iUserModel } from 'shared/user';
import { NewUserPage } from './NewUserPage';
import { UserPage } from './UserPage';
import { Avatar, Button, Col, Flex, Row, Skeleton, Space, Tag, Typography, Input, Drawer, Result, Table } from 'antd';
import { CaretLeftFilled, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { buttonBorder, topMargin, spaceWidth } from 'utils/styles';
import { TipBottomLeft, TipBottomRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { DESIGNATION_LIST } from 'shared/userDesignations';

const { Text } = Typography;
const { Search } = Input;

interface iSearchBar {
  email: string;
  id: { toString: () => string | string[] };
  roles: string | string[];
}

interface iTarget {
  target: { value: string };
}

export const UsersList = () => {
  const [users, err, loading] = useApiQuery(usersService.getUsers);
  const [filteredUsers, setFilteredUsers] = useState<iUserModel[]>([]);
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const [deleteUserDrawerOpen, setDeleteUserDrawerOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDrawerWidth] = useState(false);

  const searchViews = () => {
    const searchBar = () => {
      const handleSearch = (value: string) => {
        setFilteredUsers(
          users.filter(
            ({ email, id }: iSearchBar) => email.toLowerCase().includes(value.toLowerCase()) || id.toString().includes(value.toLowerCase()),
          ),
        );
        setSearchQuery(value);
      };
      const handleOnChange = (e: iTarget) => handleSearch(e.target.value);
      return (
        <Col span={8}>
          <Search enterButton allowClear placeholder="Search using username or ID" onChange={handleOnChange} />
        </Col>
      );
    };

    const newUserButton = () => {
      const handleOnClick = () => setAddUserDrawerOpen(true);
      return (
        <Col span={10}>
          <Flex justify={'flex-end'}>
            <Space direction="horizontal">
              <Button type="primary" onClick={handleOnClick} style={buttonBorder}>
                New User
              </Button>
            </Space>
          </Flex>
        </Col>
      );
    };

    return (
      <Row gutter={[16, 16]}>
        {searchBar()}
        <Col span={6} />
        {newUserButton()}
      </Row>
    );
  };

  const userTable = () => {
    const userList = searchQuery.length > 0 ? filteredUsers : users;

    const avatarData = () => (avatar: string) => <Avatar src={avatar} shape="square" size={32} />;
    const usernameSort = () => (a: iUserModel, b: iUserModel) => formatUserName(a.email).localeCompare(formatUserName(b.email));
    const usernameData = () => (_name: string, user: iUserModel) => <Text> {formatUserName(user.email)} </Text>;
    const rolesData = () => (roles: string[]) => roles.length > 0 && <Tag color="purple"> {roles.join(', ')} </Tag>;
    const rolesOnFilter = () => (value: string, record: { roles: string[] }) => record.roles.includes(value);
    const rolesFilter = () => ['Admin', 'Developer', 'DevOps', 'Viewer'].map(role => ({ text: role, value: role }));
    const designationData = () => (designation: string[]) => designation.length > 0 && <Tag color="purple"> {designation} </Tag>;
    const getDesignationFilters = () =>
      DESIGNATION_LIST.map(designation => ({
        text: designation,
        value: designation,
      }));

    const filterByDesignation = (value: string | number | boolean, record: iUserModel) =>
      record.designation?.toString().toLowerCase().includes(value.toString().toLowerCase());

    const statusData = () => (isBlocked: boolean) => {
      const {
        color: color,
        icon: icon,
        data: data,
      } = isBlocked ? { color: 'red', icon: <WarningFilled />, data: 'Blocked' } : { color: 'green', icon: <CheckCircleFilled />, data: 'Active' };
      return (
        <Tag color={color} icon={icon}>
          {data}
        </Tag>
      );
    };

    const loginData = () => (lastLogin: Date | string | undefined) => {
      const pastLogin = (recentLogin: Date | string | undefined): string | null =>
        recentLogin ? (!moment(recentLogin).isSame(moment(), 'day') ? moment(recentLogin).format('DD-MM-YYYY ~ hh:mm:ss A') : null) : null;

      const previousLogin = (recentLogin: Date | string | undefined): string => {
        if (!recentLogin) return 'Offline';
        const loginDate = moment(recentLogin);
        const now = moment();
        const lastDay = now.diff(loginDate, 'days');
        const lastWeek = now.diff(loginDate, 'weeks');
        const lastMonth = now.diff(loginDate, 'months');
        const lastYear = now.diff(loginDate, 'years');
        return loginDate.isSame(now, 'day')
          ? loginDate.format('DD-MM-YYYY ~ hh:mm:ss A')
          : lastDay === 0
          ? 'Offline: Since 1 day'
          : lastDay < 7
          ? `Offline: Since ${lastDay} days`
          : lastWeek === 0
          ? 'Offline: Since 1 week'
          : lastDay < 30
          ? `Offline: Since ${lastWeek} weeks`
          : lastMonth === 0
          ? 'Offline: Since 1 month'
          : lastDay < 365
          ? `Offline: Since ${lastMonth} months`
          : lastYear === 0
          ? 'Offline: Since 1 year'
          : `Offline: Since ${lastYear} years`;
      };

      return (
        <TipBottomLeft tip={pastLogin(lastLogin)}>
          <Tag color="geekblue"> {previousLogin(lastLogin)} </Tag>
        </TipBottomLeft>
      );
    };

    const actionData = () => (_: any, user: iUserModel) => {
      const handleOnClick = () => (setSelectedUserId(user.id.toString()), setDeleteUserDrawerOpen(true));
      return (
        <TipBottomRight tip="Click to manage user">
          <Button onClick={handleOnClick}> Edit </Button>
        </TipBottomRight>
      );
    };

    const userColumn = [
      { title: 'Profile', dataIndex: 'avatar', key: 'avatar', render: avatarData() },
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'Name', dataIndex: 'name', key: 'name', render: usernameData(), sorter: usernameSort() },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Role', dataIndex: 'roles', key: 'roles', render: rolesData(), onFilter: rolesOnFilter(), filters: rolesFilter() },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        render: designationData(),
        filters: getDesignationFilters(),
        filterSearch: true,
        onFilter: filterByDesignation,
      },
      { title: 'Status', dataIndex: 'isBlocked', key: 'status', render: statusData() },
      { title: 'Last Login', dataIndex: 'lastLogin', key: 'lastLogin', render: loginData() },
      { title: 'Manage', key: 'manage', render: actionData() },
    ];

    const noResult = () => {
      const backButton = () => (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Users
        </Button>
      );
      return (
        <Col span={24}>
          <Result status="404" subTitle="This User does not exist... Please search for appropriate User" extra={backButton()} style={topMargin} />
        </Col>
      );
    };

    if (userList.length === 0) return noResult();
    return <Table rowKey="id" size="small" pagination={false} dataSource={userList} columns={userColumn} />;
  };

  const drawerData = () => {
    const drawerWidth = isDrawerWidth ? '20%' : '40%';
    const drawerTitle = addUserDrawerOpen ? 'Add a new user' : 'User details';
    const handleOnOpen = addUserDrawerOpen || deleteUserDrawerOpen;
    const handleOnClose = () => (addUserDrawerOpen ? setAddUserDrawerOpen(false) : setDeleteUserDrawerOpen(false));
    const drawerContent = addUserDrawerOpen ? <NewUserPage /> : selectedUserId && <UserPage userId={selectedUserId} />;
    return (
      <Drawer width={drawerWidth} title={drawerTitle} open={handleOnOpen} onClose={handleOnClose}>
        {drawerContent}
      </Drawer>
    );
  };

  const userData = () => (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Text />
      {userTable()}
      <Text />
      {drawerData()}
    </Space>
  );

  if (!users || err || loading) return <Skeleton active={true} loading={true} />;
  return userData();
};

import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { iRegionModel } from '../../../shared/deployment';

export const ClusterPopInstallEBSButton = (props: { region: iRegionModel; clusterAreReady: boolean; refetchJobList: () => void }) => {
  const initializeEBS = props.region.plugins.includes('AWS-EBS-driver');
  const popInstallDescription = (
    <ul>
      <li> This will install EBS addon into you cluster </li>
      <li>
        <Link to={`/clusters/${props.region.id}/integrations/10`}> Read more about EBS </Link>
      </li>
    </ul>
  );
  const [ebsJobFunction, ebsJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallEBS_Job($regionId: Int!) {
      JobsController_InstallEBS_Job(regionId: $regionId) {
        id
      }
    }
  `);

  const popInstallOnConfirm = async e => {
    try {
      await ebsJobFunction({ variables: { regionId: Number(props.region.id) } });
    } catch (e) {
      console.error(`Error in createEbs`, e);
    }
  };
  useEffect(() => {
    props.refetchJobList();
  }, [ebsJobRes]);
  return (
    <Popconfirm
      title="Install Amazon Elastic Block Store (Amazon EBS)"
      description={popInstallDescription}
      onConfirm={popInstallOnConfirm}
      okText="Continue"
      cancelText="Cancel"
    >
      <Button hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'} type={initializeEBS ? 'default' : 'primary'}>
        {initializeEBS ? 'Reinstall' : 'Install'} EBS
      </Button>
    </Popconfirm>
  );
};

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { updateDeployment } from '../common';
import { CustomWidgets } from 'form-components';
import { iDeployment, iDeploymentServicesConfig } from 'shared/deployment';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { Button, Flex, Skeleton, Space, Switch, Typography } from 'antd';
import { buttonBorder, buttonWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useState } from 'react';
import { DeleteServiceBtn } from 'components/Projects/Settings/ProjectService/MainTab';
import { DeployButton } from '../new-deployment/DeployPage';

interface iService {
  onSave?: () => void;
  serviceName: string;
  inPopup?: boolean;
  deployment: iDeployment;
  schema: iDeploymentServicesConfig;
}

const { Title } = Typography;

export const DeploymentOneServiceSettings = (props: iService) => {
  const { deployment, serviceName } = props;
  const [stateService, setServiceState] = useState<boolean>(deployment?.services?.[serviceName]?.enabled);

  const FormSchema: any = props.schema?.FormSchema || {};

  if (!deployment) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleSubmit = async (e: any) => {
    const data = {};
    data[serviceName] = e.formData;
    const newData = { vars: data };
    newData[`service.${serviceName}.enabled`] = stateService;
    await updateDeployment(deployment.id, newData);
    if (props.onSave) {
      props.onSave();
    }
  };

  const handleCancel = () => {
    if (props.onSave) {
      props.onSave();
    }
  };

  const serviceStatus = (
    <Flex gap="middle" justify="space-between">
      <Title level={5}> Enable or disable service: {serviceName} </Title>
      <Switch onChange={setServiceState} checkedChildren="Enabled" unCheckedChildren="Disabled" defaultChecked={stateService} />
    </Flex>
  );
  const cancelButton = props.inPopup && (
    <Button type="default" key="back" onClick={handleCancel} style={buttonWidth}>
      Cancel
    </Button>
  );
  if (!props?.schema?.hasUIconfig) {
    return (
      <>
        {serviceStatus}
        <BottomButtons>
          {cancelButton}
          <Button type="primary" htmlType="submit" onClick={e => handleSubmit({ formData: {} })} style={buttonBorder}>
            Save
          </Button>
          <DeployButton deployment={deployment} />
        </BottomButtons>
      </>
    );
  }

  return (
    <>
      {serviceStatus}
      <Title level={5}> Configuration for service {serviceName} </Title>
      <div style={{ padding: '8px' }}>
        <Form
          widgets={CustomWidgets}
          formData={props.schema.serviceVarConfig}
          schema={FormSchema}
          uiSchema={props.schema.UISchema}
          validator={validator}
          onSubmit={handleSubmit}
          onError={(e: any) => console.log(`Error from submit: ${e}`)}
          templates={{ FieldTemplate: CustomFieldTemplate }}
        >
          <BottomButtons
            extra={<DeleteServiceBtn serviceName={serviceName} projectId={props.deployment.projectId} deploymentId={props.deployment.id} />}
          >
            <Space>
              {cancelButton}
              <Button type="primary" htmlType="submit" style={buttonBorder}>
                Save
              </Button>
            </Space>
          </BottomButtons>
        </Form>
      </div>
    </>
  );
};
